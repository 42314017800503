<template>
  <div class="all-height d-flex justify-center custom-font">
    <div class="all-width">
      <v-card class="shadow-off" loader-height="2" :loading="loading" :disabled="loading">
        <v-card-text>
          <!-- <div class="d-flex py-1">
            <div>
              <v-chip v-if="stage === 3" small label color="success">Closed</v-chip>
              <v-chip v-else-if="stage === 1" small label color="warning">Pending for review</v-chip>
              <v-chip v-else small label color="info">Open</v-chip>
            </div>
            <div class="ml-2">
              <v-chip v-if="metadata.managerlist.indexOf(userid) > -1" small label color="caccounting"
                class="white--text">Manager</v-chip>
              <v-chip v-else small label color="">General</v-chip>
            </div>
          </div> -->
          <div class="d-flex" v-if="!loading || metadata._id">
            <div class="d-flex justify-space-around  gap-tab all-width">
              <div class="border-on  d-flex justify-space-between hoverable cursor-pointer  width-tab  padding-tab"
                @click="resetFilter();filterSelectedData.selectedTaskType=''">
                <span class="" style="font-size: 13px !important; font-weight:550">Total Tasks </span>
                <span class="caccounting--text font-size-font font-weight-bold ">{{ summary.count || 0 }}</span>
              </div>
              <div class="border-on d-flex justify-space-between hoverable cursor-pointer width-tab  padding-tab"
                @click="selectedFilter = 'Closed Tasks'">
                <span class="" style="font-size: 13px !important; font-weight:550">Closed Tasks</span>
                <span class="teal--text font-size-font font-weight-bold ">{{ summary.approved || 0 }}</span>
              </div>
              <div class="border-on d-flex justify-space-between hoverable cursor-pointer width-tab  padding-tab"
                @click="selectedFilter = 'Pending Submissions'">
                <span class="" style="font-size: 13px !important; font-weight:550">Pending Submissions</span>
                <span class="Tabblue--text font-size-font font-weight-bold ">{{ summary.pending || 0 }}</span>
              </div>
              <div class="border-on d-flex justify-space-between hoverable cursor-pointer width-tab  padding-tab"
                @click="selectedFilter = 'Pending Approvals'">
                <span class="" style="font-size: 13px !important; font-weight:550">Pending Approvals</span>
                <span class="error--text font-size-font font-weight-bold ">{{ summary.pendingapproval || 0 }}</span>
              </div>
            </div>
          </div>
          <div v-if="loading && !metadata._id">
            <v-skeleton-loader type="table-heading, list-item-two-line, article, article, article"></v-skeleton-loader>
          </div>
          <div class="" v-else>
            <div class="flex-grow-1 d-block">
              <div class="px-0 pt-1">
                <div class="d-flex align-center py-1 my-1 justify-center overflow-scroll">
                  <div class="d-flex align-start" v-if="wholeChecklist.length > 0">
                    <v-container class="pa-0 ">
                      <v-combobox
                      v-model="selectedFilters"
                      :items="typeOfTask"
                      multiple
                      outlined
                      class="radius-small customDropdown"
                      placeholder="Task Type"
                      hide-details
                      @change="selectTypeOfTask"
                      style="min-width: 200px; padding: 0; line-height: 30px; min-height: 50px"
                      :menu-props="{ offsetY: true, maxHeight: '250px', maxWidth: '200px', contentClass: 'custom-list-item' }"
                    >
                    </v-combobox>
                    
                    
                    </v-container>
                    <v-container class="pa-0 mx-2" style="max-width: 200px;">
    

                      <v-combobox
                        v-model="filterSelectedData.selectedlocation"
                        :items="filteredLocationList"
                        outlined
                        class="radius-small line-clamp cursor-pointer "
                        multiple
                        placeholder="Location"
                        hide-details
                        @change="filterWithLocation"
                        style="background-color: white; min-width: 200px; padding: 0; line-height: 30px; min-height: 50px"
                        :menu-props="{ offsetY: true, maxHeight: '250px', maxWidth: '200px', contentClass: 'custom-list-item' }"
                      >
                        <!-- Custom search input -->
                        <template v-slot:prepend-item>
                          <div class="py-1 pl-2 pr-3">
                            <lb-string v-model="locationSearch" :hidedetails="true" label="Search"></lb-string>
                          </div>
                        </template>

                        <!-- Custom selection rendering -->
                        <template v-slot:selection="data">
                          <div class="cursor-pointer" >{{ data.item }}</div>
                        </template>
                      </v-combobox>   



                    </v-container>
                    <v-btn text class="shadow-off font-weight-bold text-decoration-underline" @click="reset()">Reset</v-btn>
                  </div>
                  <v-spacer></v-spacer>
                  <v-tooltip bottom content-class="tooltip-bottom"
                    v-if="multiselectdata.length > 0 && metadata.stage === 0 && selectedusertype === 1">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" class="px-1 ml-1" v-on="on" icon small
                        @click="changeChecklistStatus(multiselectdata, 'approve')" color="success"><v-icon
                          class="font-20">mdi-check-circle</v-icon></v-btn>
                    </template>
                    <span>Bulk Approve</span>
                  </v-tooltip>
                  <v-tooltip bottom content-class="tooltip-bottom"
                    v-if="multiselectdata.length > 0 && metadata.stage === 0 && selectedusertype === 1">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" class="px-1 ml-1" v-on="on" icon small
                        @click="changeChecklistStatus(multiselectdata, 'reject')" color="error"><v-icon
                          class="font-20">mdi-close-circle</v-icon></v-btn>
                    </template>
                    <span>Bulk Reject</span>
                  </v-tooltip>
                  <v-tooltip bottom content-class="tooltip-bottom"
                    v-if="multiselectdata.length > 0 && metadata.stage === 0 && selectedusertype === 2">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" class="px-2 mx-1" v-on="on" icon small
                        @click="opensendForReviewDialogbulk(multiselectdata), files=[], filesbulk=[]"><img
                          src="/img/icons/uploadvoiletcloud.svg" /></v-btn>
                    </template>
                    <span>Bulk Upload Workpaper</span>
                  </v-tooltip>
                  <v-tooltip bottom content-class="tooltip-bottom"
                    v-if="multiselectdata.length > 0 && metadata.stage === 0">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" class="px-1 ml-1 " v-on="on" icon small color="error"
                        @click="bulkunlinkBot(multiselectdata)"> <img src="/img/icons/LinkBot.svg" width="15px"
                          height="15px" /></v-btn>

                    </template>
                    <span>UnLink Bot</span>
                  </v-tooltip>
                  <v-tooltip bottom content-class="tooltip-bottom"
                    v-if="multiselectdata.length > 0 && metadata.stage === 0">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" class="px-1 ml-1" v-on="on" icon small color="success"
                        @click="bulkopenlinkBotDialog(multiselectdata)"> <img src="/img/icons/PlugsConnected.svg"
                          width="15px" height="15px" /></v-btn>
                    </template>
                    <span>Link Bot</span>
                  </v-tooltip>

                  <div class="px-2">
                    <NewConversation heading="Conversation" width="550" height="500" :recurring="false"
                      :recurringText="''" :closed="metadata.stage !== 0" @markread="metadata.conversation_read = true"
                      :notify="!metadata.conversation_read"
                      :getapi="'/v2/financialclose/project/getconversation/' + metadata._id"
                      :sendapi="'/v2/financialclose/project/addconversation/' + metadata._id"
                      :readapi="'/v2/financialclose/project/markreadconversation/' + metadata._id" :name="''"
                      :mentionapi="'/v2/financialclose/analysis/getusersformdata'" />
                  </div>
                  <v-tooltip bottom content-class="tooltip-left">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" small icon class="px-3" target="_blank" @click="downloadExcel()">
                        <img src="/img/icons/DownloadExcel.svg" width="30px" height="25px" />
                      </v-btn>
                    </template>
                    <span>Download Excel</span>
                  </v-tooltip>
                  <v-tooltip bottom content-class="tooltip-bottom">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="pa-2 ml-1" @click="downloadPPT()" v-bind="attrs" v-on="on" icon small>
                        <img src="/img/icons/DownloadPPT.svg" width="30px" height="25px" />
                      </v-btn>
                    </template>
                    <span>Download PPT</span>
                  </v-tooltip>
                  <v-tooltip bottom content-class="tooltip-bottom">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="shadow-off " icon @click="refreshData()" v-bind="attrs" v-on="on"><v-icon
                          class="font-20 px-1">mdi-refresh</v-icon></v-btn>
                    </template>
                    <span>Reload</span>
                  </v-tooltip>
                  <v-tooltip bottom content-class="tooltip-bottom"
                    v-if="multiselectdata.length > 0 && metadata.stage === 0 && metadata.managerlist.indexOf(userid) > -1">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" class="px-1 ml-1" v-on="on" color="info" icon small
                        @click="bulkActionUserdilog('preparer')"><v-icon class="font-20">mdi-account</v-icon></v-btn>
                    </template>
                    <span>Bulk Add/Remove Preparer</span>
                  </v-tooltip>
                  <v-tooltip bottom content-class="tooltip-bottom"
                    v-if="multiselectdata.length > 0 && metadata.stage === 0 && metadata.managerlist.indexOf(userid) > -1">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" class="px-1 ml-1" v-on="on" color="warning" icon small
                        @click="bulkActionUserdilog('approver')"><v-icon class="font-20">mdi-account</v-icon></v-btn>
                    </template>
                    <span>Bulk Add/Remove Approver</span>
                  </v-tooltip>

                  <!-- <div class="border-on  v-tab radius-small d-flex align-center justify-center "
                    @click="opensendForReviewDialogbulk(multiselectdata)" style="padding:6px"
                    v-if="multiselectdata.length > 0 && metadata.stage === 0">
                    <img src="/img/icons/upload-cloud.svg" class="pr-2" />
                    <span>Bulk Upload</span>
                  </div> -->

                  <div class="d-flex align-center " v-if='metadata.managerlist.indexOf(userid) > -1'>
                    <v-btn small class="mx-2 border-on shadow-off  bg-white radius-small " style=" border-radius: 6px !important;
                    background-color: white; padding-top: 21px !important;
                    padding-bottom: 21px !important;" @click="addEditForm()"
                      v-if="metadata.managerlist.indexOf(userid) > -1 && metadata.stage === 0"><v-icon class=""
                        large>mdi-plus</v-icon>New
                      Task</v-btn>
                    <BulkUpdloadCheckListNew :project="id" @reload="refreshData()" width="600" />
                  </div>
                  <div class=" ml-2 d-flex align-center justify-center"
                    v-if="metadata.managerlist.indexOf(userid) === -1">
                    <v-tabs class="" fixed-tabs hide-slider>
                      <v-tab @click="filterByPreparer" :class="{ 'grey-background': selectedusertype === 2 }"
                        class="border-on black--text" style="border-radius: 8px 0px 0px 8px;">
                        Preparer
                      </v-tab>
                      <v-tab @click="filterByApprover" :class="{ 'grey-background': selectedusertype === 1 }"
                        class="border-on black--text" style="border-radius: 0px 8px 8px 0px;">Approver</v-tab>
                    </v-tabs>
                  </div>
                </div>
                <div>
                  <div class="border-on radius-all" v-if="checklist.length > 0">
                    <v-simple-table dense class="FC-Table">
                      <template v-slot:default>
                        <thead class=" py-1 px-2 " style="background: #667085 !important;">
                          <th class="text-left ma-0  py-3 d-flex align-center justify-start">
                            <v-checkbox hide-details class="ma-0 pa-0 " color="white" v-model="selectAllCheckbox"
                              @change="toggleSelectAll"></v-checkbox>
                          </th>
                          <th class="text-left ">Task Description</th>
                          <th class="text-center px-4 max-width-div"> Location </th>
                          <th class="text-center ">Due</th>
                          <th class="text-center">TB Amount</th>
                          <th class="text-center">Schedule</th>
                          <th class="text-center">Difference</th>
                          <th class="text-left px-4">Status</th>
                          <th class="text-center " v-if="metadata.stage === 0">Action</th>
                        </thead>
                        <v-slide-y-transition class="" group tag="tbody">
                          <tr v-for="(v, k) in paginatedList" :key="k" class="" @mouseover="hover = v"
                            @mouseleave="hover = ''">
                            <td v-if="v.type === 'category'" colspan="10" class="py-2 font-weight-bold body-2 "
                              :style="`background-color:${$nova.hexToRgbA($vuetify.theme.currentTheme.caccounting, 0.1)}, border-bottom:1px solid ${$nova.hexToRgbA($vuetify.theme.currentTheme.caccounting, 0.1)}`">
                              <div class="d-flex ">
                                <v-checkbox hide-details color="caccounting" class="ma-0 pa-0"
                                  @click="checkCategoryCheckbox(v.name)"></v-checkbox>
                                <span class="px-2 py-0">

                                  {{ v.name }}
                                </span>
                              </div>
                            </td>
                            <template v-else>
                              <!--Table Checkbox -->
                              <td class="text-center " style="width:27px !important" v-if="metadata.stage === 0">
                                <v-checkbox multiple color="caccounting" style="width:25px !important"
                                  v-model="multiselectdata" :value="v._id" :key="v._id" hide-details dense  @change="handleCheckboxChange(v)"
                                  class="pa-0 ma-0"></v-checkbox>
                              </td>
                              <!--Table Description -->
                              <td class="cursor-pointer  text-left " style="width:650px" @click="viewTask(v)">
                                <!-- <v-checkbox hide-details dense class="ma-0 pa-0" v-model="multiselectdata"></v-checkbox> -->
                                <div class="max-width-div align-center  d-flex my-1"
                                  style="margin-left:-15px !important">
                                  <!-- <v-chip class="caccounting--text ma-1" color="#F9F5FF">
                                  </v-chip> -->
                                  <span class="caccounting--text ma-1 d-flex align-center justify-center px-2 py-1"
                                    style="background-color:#F9F5FF ; border-radius:8px ; font-weight:bold; font-size:12px !important ; max-height:25px">
                                    {{ v.ref }}
                                  </span>
                                  <span :style="{
                                    'margin-left': '8px',
                                  }">
                                    {{ v.description }}
                                  </span>
                                </div>
                              </td>
                              <!-- Table Location -->
                              <td class="cursor-pointer text-left  px-4" @click="viewTask(v)">
                                <div>
                                  {{ v.locationName }}
                                </div>
                              </td>
                              <!-- Table Due -->
                              <td class="text-left cursor-pointer " @click="viewTask(v)">
                                <div v-if="new Date(v.duedate || '')">
                                  <v-tooltip bottom content-class="tooltip-bottom" v-if="v.stage === 3">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-avatar size="25" v-bind="attrs" v-on="on"
                                        v-if="((new Date(v.duedate || '') - new Date(v.reviewed_on))) / (1000 * 24 * 60 * 60) < -1">
                                        {{ Math.ceil((new Date(v.reviewed_on) - new Date(v.duedate ||
                                          '')) / (1000 * 24 * 60 * 60)) + 1 }}
                                      </v-avatar>
                                    </template>
                                    <span>Delay</span>
                                  </v-tooltip>
                                  <v-tooltip bottom content-class="tooltip-bottom"
                                    v-else-if="(new Date(v.duedate || '') - new Date()) >= -1">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-avatar size="25" v-bind="attrs" v-on="on">
                                        {{ Math.ceil((new Date(v.duedate || '') - new Date()) / (1000 * 24 * 60 * 60)) +
                                          1
                                        }}
                                      </v-avatar>
                                    </template>
                                    <span>Days Remaining</span>
                                  </v-tooltip>
                                  <v-tooltip bottom content-class="tooltip-bottom"
                                    v-else-if="(new Date(v.duedate || '') - new Date()) < -1">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-avatar size="25" v-bind="attrs" v-on="on">
                                        {{ Math.ceil((new Date() - new Date(v.duedate || '')) / (1000 * 24 * 60 * 60)) +
                                          1
                                        }}
                                      </v-avatar>
                                    </template>
                                    <span>Days Overdue</span>
                                  </v-tooltip>
                                </div>
                              </td>
                              <!-- Table Tb Amount -->
                              <td class=" text-center " style="min-width:6vh" v-if="(v.__tbtotals || {}).total">{{
                                $nova.formatNumber(((v.__tbtotals ||
                                  {}).total || 0).toFixed(0)) }}</td>
                              <td class="text-center " style="min-width:6vh" v-else>0</td>
                              <!-- Table Difference -->
                              <td class=" text-center cursor-pointer " v-if="Number((v.result || {}).value?.total_value)"
                                @click.stop="populateschedulelinkdialogitems(v)">{{ $nova.formatNumber((Number((v.result
                                  ||
                                  {}).value?.total_value) ||
                                  0).toFixed(0)) }}</td>
                              <td class="text-center " v-else></td>
                              <td class="text-center ">{{ $nova.formatNumber((((v.__tbtotals || {}).total || 0) -
                                (Number((v.result ||
                                  {}).value?.total_value) || 0)).toFixed(0)) }}</td>
                              <!-- Table status -->
                              <td class=" px-4 " @click="viewTask(v)" style="width: 20vh;">
                                <div class="d-flex justify-start align-center py-1 px-3 rounded-sm  "
                                  :style="selectedusertype === 2 ? 'background-color:#FEF3F2; border-radius:6px !important ; color: #B42318 ;' : selectedusertype === 1 && 'background-color:#F2F4F7; color: #344054; border-radius:6px !important'"
                                  v-if="v.stage === 0">
                                  <v-avatar :color="selectedusertype === 2 ? '#B42318' : '#344054'" size="6"></v-avatar>
                                  <span class="px-2 py-0 ma-0 line-clamp">Pending Submission</span>
                                </div>
                                <div class="d-flex justify-start align-center py-1 px-3 rounded-sm  Tabblue--text"
                                  style="background-color:#EFF8FF; border-radius:6px !important"
                                  v-else-if="v.stage === 1">
                                  <v-avatar size="6" color="Tabblue"></v-avatar>
                                  <span class="px-2 py-0 ma-0 line-clamp">Processing</span>
                                </div>
                                <div
                                  :style="selectedusertype === 1 ? 'background-color:#FEF3F2; border-radius:6px !important ; color: #B42318 ;' : selectedusertype === 2 && 'background-color:#F2F4F7; color: #344054; border-radius:6px !important'"
                                  class="d-flex justify-start align-center py-1 px-3 rounded-sm  "
                                  v-else-if="v.stage === 2">
                                  <v-avatar :color="selectedusertype === 1 ? '#B42318' : '#344054'" size="6"></v-avatar>
                                  <span class="px-2 py-0 ma-0 line-clamp">Pending Approval</span>
                                </div>
                                <div class="d-flex justify-start align-center py-1 px-3 rounded-sm  teal--text"
                                  style="background-color:#ECFDF3; border-radius:6px !important"
                                  v-else-if="v.stage === 3">
                                  <v-avatar size="6" color="grey"></v-avatar>
                                  <span class="px-2 py-0 ma-0 line-clamp">Closed</span>
                                </div>
                              </td>


                              <!-- Table action  -->
                              <td class="text-left px-4" v-if="metadata.stage === 0">
                                <div class="d-flex align-center justify-center">
                                  <div v-if="selectedusertype === 1 && v.stage != 0 && v.approver.includes(userid)" class="cursor-pointer"
                                    @click="$nova.downloadFiles(v.upload)">
                                    <img v-if="hover === v" src="/img/icons/Downloadgreencloud.svg">
                                    <img v-else src="/img/icons/DownloadActionCloud.svg">

                                  </div>
                                  <div v-if="selectedusertype === 2 && v.preparer.includes(userid)" class="cursor-pointer"
                                    @click.stop="opensendForReviewDialog(v)">
                                    <img v-if="hover === v" src="/img/icons/uploadvoiletcloud.svg">
                                    <img v-else src="/img/icons/UploadActionClous.svg" />
                                  </div>

                                  <div class=" d-flex ">
                                    <div class="text-left px-3 py-1 ">


                                      <v-menu offset-y v-if="filterListitems.locations?.length > 1" open-on-click
                                        :close-on-content-click="false">
                                        <template v-slot:activator="{ on, attrs }">
                                          <div class="filter-hide cursor-pointer d-flex align-center justify-center"
                                            v-bind="attrs" v-on="on">
                                            <img src="/img/icons/ActionButton.svg">
                                          </div>

                                        </template>
                                        
                                          <!-- menu list -->
                                          <v-list dense class="pa-2 ma-0 border-left-default all-width white" >
                                            <v-list-item class="cursor-pointer hoverable all-width gap-4 pa-0 pl-2 " v-if="selectedusertype === 1"
                                              @click.stop="changeChecklistStatus(v._id, 'approve')">
                                              <v-icon color="green">mdi-check-circle</v-icon>
                                              Approve
                                            </v-list-item>
                                            <v-list-item class="cursor-pointer hoverable all-width gap-4 pa-0 pl-2 " v-if="selectedusertype === 1"
                                              @click="changeChecklistStatus(v._id, 'reject')">
                                              <v-icon color="#B32318">mdi-close-circle
                                              </v-icon>
                                              Reject
                                            </v-list-item>
                                            <v-list-item class="cursor-pointer hoverable all-width gap-4 pa-0 pl-1 ">
                                              <NewConversation heading="Conversation" width="600" :recurring="false"
                                                :recurringText="''" :closed="metadata.stage !== 0"
                                                @markread="v.conversation_read = true" :notify="!v.conversation_read"
                                                :getapi="'/v2/financialclose/analysis/getconversation/' + metadata._id + '/c/' + v._id"
                                                :sendapi="'/v2/financialclose/analysis/addconversation/' + metadata._id + '/c/' + v._id"
                                                :readapi="'/v2/financialclose/analysis/markreadconversation/' + metadata._id + '/c/' + v._id"
                                                :name="'Comment'"
                                                :mentionapi="'/v2/financialclose/analysis/getusersformdata'" />
                                            </v-list-item>
                                            <v-divider></v-divider>
                                            <v-list-item class="cursor-pointer hoverable all-width gap-4 pa-0 pl-2 mt-1" @click="openHelpdeskDialog(v)">
                                              <img src="/img/icons/VirtualConcierge.svg" width="15px" height="15px" />
                                              <span>Virtual Concierge</span>
                                            </v-list-item>
                                            <v-list-item class="cursor-pointer hoverable all-width gap-4 pa-0 pl-2 mb-1" @click="openlinkBotDialog(v)">
                                              <img src="/img/icons/LinkBot.svg" width="15px" height="15px" />
                                              <span> Link bot</span>
                                            </v-list-item>
                                            <v-divider></v-divider>
                                            <v-list-item class="cursor-pointer hoverable all-width gap-4 pa-0 mt-1">
                                              <lb-activitylognew :config="activitylogconfig"
                                                :url="`/v2/financialclose/analysis/getlogs/${metadata._id}/c/${v._id}`"
                                                :name="'Activity Log'" />
                                            </v-list-item>
                                          </v-list>
                                      </v-menu>
                                      <span v-else>NA</span>
                                    </div>
                                  </div>


                                </div>
                              </td>
                            </template>
                          </tr>
                        </v-slide-y-transition>
                      </template>
                    </v-simple-table>
                    <div class="d-flex justify-space-between align-center py-4 px-4">
                      <div class="d-flex ">
                        <v-btn class="radius-small border-on font-weight-bold mx-1" height="3vh"
                          style="font-size:12px !important; border-radius: 8px !important; background-color:white;"
                          @click="prevPage" :class="{ 'cursor-not-allowed': currentPage === 1 }">Previous</v-btn>
                        <v-btn class="radius-small border-on font-weight-bold mx-1" height="3vh"
                          style="font-size:12px !important; border-radius: 8px !important; background-color:white;"
                          @click="nextPage" :class="{ 'cursor-not-allowed': currentPage === totalPages }">Next</v-btn>
                      </div>
                      <span class="mx-2  font-weight-bold" style="font-size:14px !important">Page {{ currentPage }} of
                        {{ totalPages
                        }}</span>
                    </div>
                  </div>
                  <div class="d-flex align-start justify-center pt-10  radius-small  "
                    style="height: 70vh; font-size:17px !important" v-else>
                    No Task Found</div>
                </div>
              </div>

            </div>
          </div>

        </v-card-text>
      </v-card>

      <lb-dailogboxnew v-model="bulkactionuserdialog" :heading="'Manage ' + bulkactionuserusertype" width="500"
        :loading="bulkactionuserloading">
        <template v-slot:body>
          <div class="switch-custom-color">
            <div class="d-flex align-center mb-1">
              <div class="display-1 mr-2">{{ multiselectdata.length || 0 }}</div>
              <div class="line-1-2">Item(s)<br />Selected</div>
              <v-spacer></v-spacer>
              <lb-dropdown hidedetails v-if="!isLocation" label="Select Action*" v-model="selectaction"
                :items="bulkactionuserusertype === 'Approver' ? itemapprover : itempreparer" itemtext="name"
                itemvalue="value" class="mt-2" width="10"
                @change="actionselection(selectaction, bulkactionuserusertype)" />
            </div>
            <div v-if="bulkactionuserselected">
              Below selected user will be removed from all the selected items.
            </div>
            <div v-else>
              Below selected user will be added to all the selected items.
            </div>
            <lb-dropdown hidedetails label="Select User*" v-if="!isLocation" v-model="bulkactionuserid"
              :items="possibleusers" itemtext="displayname" itemvalue="_id" class="mt-2" />

            <lb-dropdown hidedetails label="Select Location*" v-if=isLocation v-model="bulkactionuserid"
              :items="formdata.location" itemtext="displayname" itemvalue="_id" class="mt-2" />

            <lb-dropdown hidedetails :label="'Select ' + bulkactionuserusertype" v-model="changetouser"
              :items="formdata.users" itemtext="displayname" itemvalue="_id" class="mt-2"
              v-if="selectaction === 'replace'" :disabled="bulkactionuserid ? false : true" />
          </div>
        </template>
        <template v-slot:actions>
          <v-spacer></v-spacer>
          <v-btn color="error" class="white--text" small v-if="selectaction === 'remove'"
            @click="bulkManageUser(selectaction, bulkactionuserusertype, bulkactionuserid, multiselectdata)">Remove</v-btn>
          <v-btn color="fbcPrimary" class="white--text" small v-else-if="selectaction === 'associate'"
            @click="bulkManageUser(selectaction, bulkactionuserusertype, bulkactionuserid, multiselectdata)">Add</v-btn>
          <v-btn color="fbcPrimary" class="white--text" small v-else-if="selectaction === 'replace'"
            @click="bulkManageUser(selectaction, bulkactionuserusertype, bulkactionuserid, multiselectdata, changetouser)">Replace</v-btn>
          <v-btn color="fbcPrimary" class="white--text" small v-else-if="selectaction === 'move'"
            @click="bulkManageUser(selectaction, bulkactionuserusertype, bulkactionuserid, multiselectdata)">Change</v-btn>
          <v-btn color="fbcPrimary" class="white--text" small v-else-if="selectaction === 'update'"
            @click="bulkManageUser(selectaction, bulkactionuserusertype, bulkactionuserid, multiselectdata)">Update</v-btn>
        </template>
      </lb-dailogboxnew>

      <lb-dailogboxnew v-model="addeditdialog" :heading="addeditdata._id ? 'Update' : 'Add New'" width="600"
        :loading="addeditloading">
        <template v-slot:body>
          <div>
            <v-row class="mt-1">
              <v-col cols="12" class="my-0 py-0">
                <lb-string v-model="addeditdata.description" label="Finance Close Task Description *"
                  :error="addeditdataerror.description" />
              </v-col>
              <v-col cols="11" class="my-0 py-0">
                <lb-string v-model="addeditdata.category" label="Balance sheet/Income statement fbcPrimary classification*"
                  :error="addeditdataerror.category" />
              </v-col>
              <v-col cols="1" class="py-0 mt-n1 ml-n4">
                <v-menu open-on-hover transition="scale-transition" elevation-0 max-width="300" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="ma-2" text icon color="lighten-2" v-bind="attrs" v-on="on">
                      <v-icon>mdi-information</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-text class="text-6 font-weight-bold">
                      "Property, plan and equipment, Trade payables, Revenue from subscription"
                    </v-card-text>
                  </v-card>
                </v-menu>
              </v-col>
              <v-col cols="11" class="my-0 py-0">
                <lb-string v-model="addeditdata.subcategory"
                  label="Balance sheet/Income statement secondary classification*"
                  :error="addeditdataerror.subcategory" />
              </v-col>
              <v-col cols="1" class="py-0 mt-n1 ml-n4">
                <v-menu open-on-hover transition="scale-transition" elevation-0 max-width="300" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="ma-2" text icon color="lighten-2" v-bind="attrs" v-on="on">
                      <v-icon>mdi-information</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-text class="text-6 font-weight-bold">
                      "Non-current assets, Current liabilities, Revenue from operations etc…"
                    </v-card-text>
                  </v-card>
                </v-menu>
              </v-col>
              <v-col cols="12" class="my-0 py-0">
                <lb-dropdown v-model="addeditdata.location" :items="formdata.locations" itemtext="name" itemvalue="_id"
                  label='Location*' :error="addeditdataerror.location" />
              </v-col>
              <v-col cols="6" class="my-0 py-0" v-if="isLocationSelected || addeditdata._id">
                <lb-dropdown v-model="addeditdata.preparer" :items="finaluserlist" itemtext="name" itemvalue="_id"
                  multiple label="Preparer(s)" :error="addeditdataerror.preparer" />
              </v-col>
              <v-col cols="6" class="my-0 py-0" v-if="isLocationSelected || addeditdata._id">
                <lb-dropdown hidden v-model="addeditdata.approver" :items="finaluserlist" itemtext="name"
                  itemvalue="_id" multiple label="Approver(s)" :error="addeditdataerror.approver" />
              </v-col>
              <v-col cols="6" class="my-0 py-0">
                <lb-textarea v-model="addeditdata.purpose" label="Purpose" :error="addeditdataerror.purpose" />
              </v-col>
              <v-col cols="6" class="my-0 py-0">
                <lb-textarea v-model="addeditdata.instruction" label="Instruction"
                  :error="addeditdataerror.instruction" />
              </v-col>
              <v-col cols="12" class="my-0 py-0">
                <lb-dropdown v-model="addeditdata.assertions" :items="possibleassertions" multiple label="Assertions"
                  :error="addeditdataerror.assertions" />
              </v-col>
              <v-col cols="6" class="my-0 py-0">
                <lb-date v-model="addeditdata.duedate" label="Due Date" :error="addeditdataerror.duedate"
                  :min="$nova.formatDateISO(new Date())" />
              </v-col>
              <v-col cols="12" class="my-0 py-0">
                <div class="d-flex align-center">
                  <span class="mr-2 font-weight-bold">Linked GLs:</span>

                  <v-btn class="shadow-off" v-if="filteredItems.length > 0"
                    @click="openSelectGlDialog(addeditdata.__tbtotals?.items || [])" small text>
                    <v-icon class="mr-1">mdi-pencil</v-icon>Update TB Link</v-btn>
                  <v-btn class="shadow-off" v-else @click="NotavailablepopTb = true;" small text>
                    <v-icon class="mr-1">mdi-pencil</v-icon>Update TB Link</v-btn>
                </div>
                <div class="mt-2" v-if="selectedGlCodes.length > 0">

                  <v-tooltip bottom content-class="tooltip-bottom" v-for="(v, k) in (selectedGlCodes || [])" :key="k">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip small outlined v-bind="attrs" v-on="on" class="mb-1 mr-1"><v-icon
                          class="mr-1">mdi-tag</v-icon>{{ v[0].glcode }}</v-chip>
                    </template>
                  </v-tooltip>
                </div>
                <div class="mt-2" v-else>
                  <v-tooltip bottom content-class="tooltip-bottom" v-for="(v, k) in (defaultGlCodes || [])" :key="k">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip small outlined v-bind="attrs" v-on="on" class="mb-1 mr-1"><v-icon
                          class="mr-1">mdi-tag</v-icon>{{ v }}</v-chip>
                    </template>
                  </v-tooltip>
                </div>

              </v-col>
            </v-row>
          </div>
        </template>
        <template v-slot:actions>
          <span class="caption">*Required</span>
          <v-spacer></v-spacer>
          <v-btn small color="fbcPrimary" class="white--text" @click="addEditChecklistItem()">
            <span v-if="addeditdata._id">Update</span>
            <span v-else>Add</span>
          </v-btn>
        </template>
      </lb-dailogboxnew>

      <lb-dailogboxnew v-model="helpdeskdialog" heading="New Query" width="600" :loading="helpdeskloading">
        <template v-slot:body>
          <div class="d-flex align-center  ">
            <span class="caccounting--text mr-1  d-flex align-center justify-center px-2 py-1"
              style="background-color:#F9F5FF ; border-radius:8px ; font-weight:bold; font-size:15px !important ; max-height:25px">
              {{ helpdeskitem.ref }}
            </span>
            <div class="font-weight-bold px-1 align-center d-flex">{{ helpdeskitem.description || "" }}</div>
          </div>
          <div class=" my-2" style="color: #475467;">If you need Consark's assistance to complete this task or if you
            have any query
            relating to this tasklist item, you can submit this form to connect with our functional experts for quick
            resolution.</div>
          <div v-if="(helpdeskitem.__existingtickets || []).length > 0 && !helpdeskitemnew">
            <div class="mt-3">
              <span class="font-weight-bold">Describe your request</span>
              <div class="text-field">
                <textarea class="input" rows="4" cols="12" placeholder="Type the request here"
                  v-model="helpdeskitem.__ticketdescripton"></textarea>
              </div>
            </div>
            <!-- <div class="font-weight-bold  mt-3">Existing Tickets</div> -->
            <!-- <div v-for="(v, k) in helpdeskitem.__existingtickets" :key="k"
              class="py-1 px-2 border-on my-1 radius-small hoverable cursor-pointer hover-tabs line-clamp"
              @click.stop="$nova.gotoLink({ path: '/financialclose/functionalsupport/' + metadata._id + '/support/' + v._id })">

              {{ v.description }}
            </div> -->
          </div>
          <div v-if="helpdeskitemnew">
            <v-btn v-if="(helpdeskitem.__existingtickets || []).length > 0" text small class="shadow-off mb-2"
              @click="helpdeskitemnew = false;"><v-icon class="mr-1">mdi-arrow-left</v-icon>Back to existing
              tickets</v-btn>

            <div class="mt-3">
              <span class="font-weight-bold">Describe your request</span>
              <div class="text-field">
                <textarea class="input" rows="4" cols="12" placeholder="Type the request here"
                  v-model="helpdeskitem.__ticketdescripton"></textarea>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:actions>
          <v-spacer></v-spacer>
          <div small
            class="white--text caccounting cursor-pointer radius-small py-2 px-3 d-flex align-center justify-cenetr"
            v-if="metadata.stage === 0" @click="addHelpdeskQuery()">
            <img src="/img/icons/UploadICon.svg" width="15px" height="15px">
            <span class="pl-2 pr-1">Submit</span>
          </div>
          <v-spacer></v-spacer>

        </template>
      </lb-dailogboxnew>

      <lb-dailogboxnew v-model="deletedialog" heading="Delete checklist item" width="600" :loading="deleteloading">
        <template v-slot:body>
          <div>
            <div class="body-1 mb-4">
              <span class="font-weight-bold">{{ deleteitem.ref }}</span>
              <span class=""><v-icon>mdi-circle-small</v-icon></span>
              <span>{{ deleteitem.description }}</span>
            </div>
            <div>You are about to delete the above tasklist item from this project.</div>
            <div class="font-weight-bold">Do you want to proceed?</div>
          </div>
        </template>
        <template v-slot:actions>
          <v-spacer></v-spacer>
          <v-btn small color="error" @click="deleteChecklistItem()">Proceed</v-btn>
        </template>
      </lb-dailogboxnew>

      <v-dialog v-model="commentsdialog" max-width="600" persistent>
        <v-card :loading="commentsloading" :disabled="commentsloading" loader-height="2" class="">
          <v-card-text class="fbcPrimary white--text d-flex align-center py-2 px-4">
            <span class="subtitle-1 white--text">Conversations</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="commentsdialog = false" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
          </v-card-text>
          <v-card-text class="mt-4">
            <div v-if="(conversations || []).length === 0" class="title text-center my-5 grey--text">
              No conversations
            </div>
            <div v-else>
              <div class="">
                <perfect-scrollbar class="comments-container">
                  <template v-for="(v, k) in conversations">
                    <div
                      :class="`d-flex align-center ${($store.state.user.id !== v.user) ? '' : 'flex-row-reverse text-right'}`"
                      :key="k">
                      <div
                        :class="`comment-text border-on radius-on px-3 py-1 my-1 ${($store.state.user.id !== v.user) ? 'grey lighten-2' : 'text-left'}`">
                        {{ v.comment }}</div>
                      <div class="mx-1 caption">
                        {{ $nova.formatDate(v.created_at, "DD-MMM-YYYY, H:m", "") }}
                      </div>
                    </div>
                  </template>
                </perfect-scrollbar>
              </div>

            </div>

          </v-card-text>
          <v-card-text class="pb-1 d-flex align-center" v-if="metadata.stage === 0">
            <v-checkbox dense hide-details class="my-0 py-0"></v-checkbox>
            <span>Recurring comment</span>
            <v-tooltip right content-class="tooltip-right">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon small><v-icon>mdi-help-circle-outline</v-icon></v-btn>
              </template>
              <div class="text-center">Tick this to add this conversation to all <br />project's tasklist having same
                reference ID</div>
            </v-tooltip>
          </v-card-text>
          <v-card-text class="d-flex align-center" v-if="metadata.stage === 0">
            <lb-string v-model="conversationtext" label="Write here..." :error="conversationtexterror" hidedetails />
            <v-btn icon class="ml-2" color="fbcPrimary" @click="addComment()"><v-icon>mdi-send</v-icon></v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>

      <lb-dailogboxnew v-model="pbclinkdialog" heading="Information Manager Links" width="600">
        <template v-slot:body>
          <div>
            <div class="d-flex align-center">
              <span>{{ pbclinkdialogchecklistdata.category }}</span>
              <span><v-icon>mdi-chevron-right</v-icon></span>
              <span>{{ pbclinkdialogchecklistdata.subcategory }}</span>
            </div>
            <div class="d-flex body-1 mb-2">
              <span class="font-weight-bold">{{ pbclinkdialogchecklistdata.ref }}</span>
              <span class=""><v-icon>mdi-circle-small</v-icon></span>
              <div class="">{{ pbclinkdialogchecklistdata.description || "" }}</div>
            </div>
            <div class="d-flex">
              <div class="display-1 mr-3 font-weight-thin">{{ (pbclinkdialogchecklistdata.__pbccount || {}).total }}
              </div>
              <div class="flex-grow-1">
                <div style="max-width:100px">
                  {{ (((((pbclinkdialogchecklistdata.__pbccount || {}).completed ||
                    0) / ((pbclinkdialogchecklistdata.__pbccount || {}).total || 0)) || 0) * 100).toFixed(0) }}% Completed
                  <v-progress-linear :height="10" color="success"
                    :value="Math.max(((((pbclinkdialogchecklistdata.__pbccount || {}).completed || 0) / ((pbclinkdialogchecklistdata.__pbccount || {}).total || 0)) || 0) * 100, 2)">
                  </v-progress-linear>
                </div>
              </div>
            </div>
            <div v-if="(pbclinkdialogitems || []).length === 0" class="title text-center my-5 grey--text">
              No Data
            </div>
            <div v-else>
              <div class="mt-4">
                <div class="font-weight-bold">Request List</div>
                <perfect-scrollbar style="max-height: 65vh">
                  <div v-for="(v, k) in pbclinkdialogitems" :key="k" class="hoverable">
                    <div class="d-flex align-start py-1 px-2">
                      <span class="mr-1">
                        <v-tooltip left content-class="tooltip-left" v-if="v.stage === -1">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" color="">mdi-cancel</v-icon>
                          </template>
                          <span>Cancelled Request</span>
                        </v-tooltip>
                        <v-tooltip left content-class="tooltip-left" v-else-if="v.stage === 0">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" color="info">mdi-timer-sand</v-icon>
                          </template>
                          <span>Waiting Response</span>
                        </v-tooltip>
                        <v-tooltip left content-class="tooltip-left" v-else-if="v.stage === 1">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" color="warning">mdi-timer-sand</v-icon>
                          </template>
                          <span>Pending Approval</span>
                        </v-tooltip>
                        <v-tooltip left content-class="tooltip-left" v-else-if="v.stage === 2">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" color="success">mdi-check-circle</v-icon>
                          </template>
                          <span>Approved Request</span>
                        </v-tooltip>
                      </span>
                      <div>{{ v.description }} </div>
                      <v-spacer></v-spacer>
                    </div>
                    <v-divider></v-divider>
                  </div>
                </perfect-scrollbar>
              </div>
            </div>
          </div>
        </template>
      </lb-dailogboxnew>

      <lb-dailogboxnew v-model="tblinkdialog" heading="Trial Balance Amount Breakup" width="1000">
        <template v-slot:body>
          <div>
            <div class="d-flex align-center">
              <span>{{ tblinkdialogchecklistdata.category }}</span>
              <span><v-icon>mdi-chevron-right</v-icon></span>
              <span>{{ tblinkdialogchecklistdata.subcategory }}</span>
            </div>
            <div class="d-flex body-1 mb-2">
              <span class="font-weight-bold">{{ tblinkdialogchecklistdata.ref }}</span>
              <span class=""><v-icon>mdi-circle-small</v-icon></span>
              <div class="">{{ tblinkdialogchecklistdata.description || "" }}</div>
            </div>
            <div class="body-1 mb-2">
              <span class="font-wight-bold">Total Closing:</span>
              {{ $nova.formatNumber(((tblinkdialogchecklistdata.__tbtotals || {}).total || 0).toFixed(0)) }}
            </div>
            <div v-if="(tblinkdialogitems || []).length === 0" class="title text-center my-5 grey--text">
              No Data
            </div>
            <div v-else>
              <v-simple-table dense class="FC-Table">
                <template v-slot:default>
                  <thead>
                    <th class="text-left">GL Code</th>
                    <th class="text-left">Type</th>
                    <th class="text-left">Name</th>
                    <th class="text-left">Location</th>
                    <th class="text-right">Opening</th>
                    <th class="text-right">Transaction</th>
                    <th class="text-right">Audit Entries</th>
                    <th class="text-right">Closing</th>
                  </thead>
                  <v-slide-y-transition class="" group tag="tbody" style="overflow:hidden !important;">
                    <tr v-for="(v, k) in tblinkdialogitems" :key="k" class="">
                      <td class="">{{ v.glcode }}</td>
                      <td class="">{{ v.type }}</td>
                      <td class="">{{ v.name }}</td>
                      <td class="" v-if="v.location_detail.length > 0">{{ v.location_detail[0].name }}</td>
                      <td class="text-right">{{ $nova.formatNumber(v.opening_amount.toFixed(0)) }}</td>
                      <td class="text-right">{{ $nova.formatNumber(v.transaction.toFixed(0)) }}</td>
                      <td class="text-right">{{ $nova.formatNumber(v.audit_entries.toFixed(0)) }}</td>
                      <td class="text-right">{{ $nova.formatNumber(v.closing_amount.toFixed(0)) }}</td>
                    </tr>
                  </v-slide-y-transition>
                </template>
              </v-simple-table>
            </div>
          </div>
        </template>
      </lb-dailogboxnew>

      <lb-dailogboxnew v-model="schedulelinkdialog" heading="Schedule Amount Breakup" width="800">
        <template v-slot:tabs>
          <div class=" ml-3">
            <div class="d-flex align-center">
              <span>{{ schedulelinkdialogchecklistdata.category }}</span>
              <span><v-icon>mdi-chevron-right</v-icon></span>
              <span>{{ schedulelinkdialogchecklistdata.subcategory }}</span>
            </div>
            <div class="d-flex body-1 ">
              <span class="font-weight-bold">{{ schedulelinkdialogchecklistdata.ref }}</span>
              <span class=""><v-icon>mdi-circle-small</v-icon></span>
              <div class="">{{ schedulelinkdialogchecklistdata.description || "" }}</div>
            </div>
          </div>
        </template>

        <template v-slot:body>
          <div>
            <div class="body-1 mb-2">
              <span class="font-wight-bold">Total Closing:</span>
              {{ $nova.formatNumber((schedulelinkdialogchecklistdata.result?.value?.total_value || 0).toFixed(0)) }}
            </div>
            <div v-if="(schedulelinkdialogitems || []).length === 0" class="title text-center my-5 grey--text">
              No Data
            </div>
            <div v-else>
              <v-simple-table dense class="FC-Table">
                <template v-slot:default>
                  <thead>
                    <th class="text-left">Particulars</th>
                    <th class="text-right">Amount</th>
                    <template v-for="(v, k) in schedulelinkdialogitems[0] || {}">
                      <th
                        v-if="k !== 'Amount' && k !== 'Particulars' && k !== 'Task No' && k !== 'Task description' && k !== 'Location'"
                        :key="k">{{ k }}</th>
                    </template>
                  </thead>
                  <v-slide-y-transition class="" group tag="tbody" style="overflow:hidden !important;">
                    <tr v-for="(v, k) in schedulelinkdialogitems" :key="k" class="">
                      <td class="">{{ v.Particulars }}</td>
                      <td class="text-right">{{ $nova.formatNumber(Number(v.Amount || 0).toFixed(0)) }}</td>
                      <template v-for="(vv, kk) in schedulelinkdialogitems[0] || {}">
                        <!-- {{  kk }} -->
                        <td
                          v-if="kk !== 'Amount' && kk !== 'Particulars' && kk !== 'Task No' && kk !== 'Task description' && kk !== 'Location'"
                          :key="kk">{{ v[kk] }}</td>
                      </template>
                    </tr>
                  </v-slide-y-transition>
                </template>
              </v-simple-table>
            </div>
          </div>
        </template>
      </lb-dailogboxnew>
      <!-- taskdetails -->
      <lb-dailogboxnew v-model="viewtaskdialog" heading="Task Details" width="800">
        <template v-slot:tabs>
          <div class="d-flex justify-space-between w-100 py-2" style="width: 100%;">
            <div class="border-on d-flex radius-small">
              <div class=" d-flex px-2 py-1">
                <div v-if="new Date(viewtaskdata.duedate || '')">
                  <template v-if="viewtaskdata.stage === 3">
                    <v-chip color="white" class="red--text"
                      v-if="(new Date(viewtaskdata.duedate || '') - new Date(viewtaskdata.reviewed_on)) < -1" label
                      small>
                      <img src="/img/icons/CalenderRed.svg" alt="">
                      <span class="pl-3">
                        {{ Math.ceil((new Date(viewtaskdata.reviewed_on || '') - new
                          Date(viewtaskdata.duedate)) / (1000 * 24 * 60 * 60)) + 1 }} days delay
                      </span>
                    </v-chip>
                  </template>
                  <v-chip color="white" v-else-if="(new Date(viewtaskdata.duedate || '') - new Date()) >= -1" label
                    small>
                    <img src="/img/icons/CalenderIcon.svg" alt="" srcset="">
                    <span class="pl-2">
                      {{ Math.ceil((new Date(viewtaskdata.duedate || '') - new Date()) / (1000 * 24 * 60 * 60)) + 1 }}
                      days remaining
                    </span>
                  </v-chip>
                  <v-chip color="white" class="red--text"
                    v-else-if="(new Date(viewtaskdata.duedate || '') - new Date()) < -1" label small>
                    <img src="/img/icons/CalenderRed.svg" alt="">
                    <span class="pl-2">
                      {{ Math.ceil((new Date() - new Date(viewtaskdata.duedate || '')) / (1000 * 24 * 60 * 60)) + 1 }}
                      days overdue
                    </span>
                  </v-chip>
                  <v-chip v-else label small icon color="white">
                    <img src="/img/icons/CalenderIcon.svg" alt="" srcset="">
                    <span class="pl-2">No date</span>
                  </v-chip>
                </div>
              </div>
              <v-divider vertical></v-divider>
              <div class=" d-flex align-center justify-center px-3 py-1">
                <img src="/img/icons/LocationIcon.svg" alt="">
                <span class="pr-2">
                  {{ viewtaskdata.locationName }}
                </span>
              </div>
            </div>
            <div class="">
              <div label small class="py-2 px-3 border-on error white--text radius-small"
                v-if="!viewtaskdata.isMatched">Unmatched
              </div>
              <div label small class="py-1 px-3 border-on  radius-small" v-else>Matched</div>
            </div>
          </div>
          <v-divider></v-divider>
        </template>
        <template v-slot:body>
          <div class="">
            <!-- Sub category -->
            <div class="d-flex align-center pb-2">
              <span>{{ viewtaskdata.category }}</span>
              <span><v-icon>mdi-chevron-right</v-icon></span>
              <span>{{ viewtaskdata.subcategory }}</span>
            </div>
            <!-- Task name  -->
            <div class="d-flex align-center body-1 mb-2">
              <span class="caccounting--text mr-1 mt-1  d-flex align-center justify-center px-2 py-1"
                style="background-color:#F9F5FF ; border-radius:3px ; font-weight:bold; font-size:15px !important ; max-height:25px">
                {{ viewtaskdata.ref }}
              </span>
              <div class="font-weight-bold px-1">{{ viewtaskdata.description || "" }}</div>
            </div>

            <v-row>
              <!-- info tabs -->
              <v-col cols="12" class="mt-2">
                <div class="d-flex  align-center">
                  <div
                    class="mr-6  border-on width-tab pa-1 tab-shadow radius-small align-center justify-cneter d-flex flex-column ">
                    <div class="title caccounting--text bold-font"
                      v-if="(tblinkdialogchecklistdata.__tbtotals || {}).total">{{
                        $nova.formatNumber(((tblinkdialogchecklistdata.__tbtotals || {}).total || 0).toFixed(0)) }}
                    </div>
                    <div class="title caccounting--text bold-font" v-else>0</div>
                    <div>Trial Balance</div>
                  </div>
                  <div
                    class="mr-6 border-on width-tab pa-1 tab-shadow radius-small align-center justify-cneter d-flex flex-column">
                    <div class="title bold-font"
                      v-if="Number((schedulelinkdialogchecklistdata.result || {}).value?.total_value)">
                      {{
                        $nova.formatNumber((Number((schedulelinkdialogchecklistdata.result || {}).value?.total_value) ||
                          0).toFixed(0)) }}</div>
                    <div class="title bold-font" v-else>0</div>
                    <div>Schedule Balance</div>
                  </div>
                  <div
                    class="border-on width-tab mr-6 pa-1 tab-shadow radius-small align-center justify-cneter d-flex flex-column">
                    <div class="error--text title bold-font">{{
                      $nova.formatNumber((((tblinkdialogchecklistdata.__tbtotals ||
                        {}).total ||
                        0) - (Number((schedulelinkdialogchecklistdata.result || {}).value?.total_value) ||
                          0)).toFixed(0)) }}
                    </div>
                    <div>Difference</div>
                  </div>
                  <div
                    class="border-on width-tab pa-1 tab-shadow radius-small align-center justify-cneter d-flex flex-column">
                    <div class="title Tabblue--text bold-font">{{ viewtaskdata.threshold || 0 }}</div>
                    <div>Threshold</div>
                  </div>
                  <v-spacer></v-spacer>
                </div>
              </v-col>
              <v-col v-if="viewtaskdata.remark">
                <div class="body-2 font-weight-bold text-sm" style="font-size:12px !important">Remark</div>
                <div>{{ viewtaskdata.remark }}</div>
              </v-col>
              <v-col v-if="fileNamesList.length>0">
                <template v-if="(fileNamesList || [])?.length > 0">
                  <v-col v-for="(v, k) in fileNamesList" :key="k" cols="4" class=" d-flex align-center justify-start">
                          <p class="ma-0 black--text ">{{ v.uploadname || v.originalname || modifiedname
                              }}</p>
                              <v-btn @click="$nova.downloadFile(v._id)" 
                                  class="cursor-pointer radius-mini  border-dark" icon>
                                  <v-icon>mdi-download</v-icon>
                              </v-btn>
                  </v-col>
              </template>
              </v-col>
              <!-- Linked Gl table -->
              <v-col cols="12">
                <div class="d-flex align-center mb-2">
                  <span class="body-2 font-weight-bold text-sm" style="font-size:12px !important">Linked GL(s)</span>
                  <v-spacer></v-spacer>
                  <div v-if="(tblinkdialogitems || []).length > 0">
                    <span class="font-wight-bold ">Total Closing:</span>
                    {{ $nova.formatNumber(((tblinkdialogchecklistdata.__tbtotals || {}).total || 0).toFixed(0)) }}
                  </div>
                </div>
                <v-simple-table dense class="FC-Table mb-2 raduis-small border-on" v-if="(tblinkdialogitems || []).length > 0">
                  <template v-slot:default>
                    <thead class="py-2" style=" background-color: #D9DDE4 !important;
                        color: black !important;">
                      <th style=" background-color: #D9DDE4 !important;
                        color: black !important;" class=" font-weight-medium py-2 text-left  ">GL Code</th>
                      <th style=" background-color: #D9DDE4 !important;
                        color: black !important;" class=" font-weight-medium py-2 text-left ">Type</th>
                      <th style=" background-color: #D9DDE4 !important;
                        color: black !important;" class=" font-weight-medium py-2 text-left ">Name</th>
                      <th style=" background-color: #D9DDE4 !important;
                        color: black !important;" class=" font-weight-medium py-2 text-left t">Opening</th>
                      <th style=" background-color: #D9DDE4 !important;
                        color: black !important;" class=" font-weight-medium py-2 text-left t">Transaction</th>
                      <th style=" background-color: #D9DDE4 !important;
                        color: black !important;" class=" font-weight-medium py-2 text-left t">Audit Entries</th>
                      <th style=" background-color: #D9DDE4 !important;
                        color: black !important;" class=" font-weight-medium py-2 text-left t">Closing</th>
                    </thead>
                    <v-slide-y-transition class="" group tag="tbody" style="overflow:hidden !important;">
                      <tr v-for="(v, k) in tblinkdialogitems" :key="k" class="">
                        <td class=" py-2 ">{{ v.glcode }}</td>
                        <td class=" py-2 ">{{ v.type }}</td>
                        <td class=" py-2 ">{{ v.name }}</td>
                        <td class=" py-2 text-left">{{ $nova.formatNumber(v.opening_amount.toFixed(0)) }}</td>
                        <td class=" py-2 text-left">{{ $nova.formatNumber(v.transaction.toFixed(0)) }}</td>
                        <td class=" py-2 text-left">{{ $nova.formatNumber(v.audit_entries.toFixed(0)) }}</td>
                        <td class=" py-2 text-left">{{ $nova.formatNumber(v.closing_amount.toFixed(0)) }}</td>
                      </tr>
                    </v-slide-y-transition>
                  </template>
                </v-simple-table>
                <div v-else class="grey--text ">No Data</div>
              </v-col>
              <!-- Schedule breakup table -->
              <v-col cols="12">
                <div class="d-flex align-center">
                  <span class="body-2 font-weight-bold text-sm" style="font-size:12px !important">Schedule
                    Breakup</span>
                  <v-spacer></v-spacer>
                  <div v-if="(schedulelinkdialogitems || []).length > 0">
                    <span class="font-wight-bold">Total Amount:</span>
                    {{ $nova.formatNumber((schedulelinkdialogchecklistdata.result?.value?.total_value ||
                      0).toFixed(0)) }}
                  </div>
                </div>
                <v-simple-table dense class="radius-small FC-Table border-on mb-2"
                  v-if="(schedulelinkdialogitems || []).length > 0">
                  <template v-slot:default>
                    <thead style=" background-color: #D9DDE4 !important;
                        color: black !important;">
                      <th class="text-left font-weight-medium"
                        style=" background-color: #D9DDE4 !important;  color: black !important;">Particulars</th>
                      <th class="text-left  font-weight-medium"
                        style=" background-color: #D9DDE4 !important; color: black !important;">Amount</th>
                      <template v-for="(v, k) in schedulelinkdialogitems[0] || {}">
                        <th class="textl-left font-weight-medium" v-if="k !== 'Amount' && k !== 'Particulars'" :key="k">
                          {{ k }}</th>
                      </template>
                    </thead>
                    <v-slide-y-transition class="" group tag="tbody" style="overflow:hidden !important;">
                      <tr v-for="(v, k) in schedulelinkdialogitems" :key="k" class="">
                        <td class="text-left">{{ v.Particulars }}</td>
                        <td class="text-left">{{ $nova.formatNumber(Number(v.Amount || 0).toFixed(0)) }}</td>
                        <template v-for="(vv, kk) in schedulelinkdialogitems[0] || {}">
                          <td class="text-left" v-if="kk !== 'Amount' && kk !== 'Particulars'" :key="kk">{{ v[kk] }}
                          </td>
                        </template>
                      </tr>
                    </v-slide-y-transition>
                  </template>
                </v-simple-table>
                <div v-else class="grey--text  ">No Data</div>
              </v-col>
              <v-col cols="12">
                <!-- Preparer approver -->
                <v-row>
                  <v-col cols="6">
                    <div class="">
                      <span class="body-2 font-weight-bold text-sm" style="font-size:12px !important">Preparer(s)</span>
                      <template v-if="(viewtaskdata.preparer || []).length > 0">
                        <div class="border-on  radius-small">
                          <div v-for="(v, k) in viewtaskdata.preparer" :key="k" class=" py-2 radius-small"
                            style="border-bottom:1px solid #D9DDE4">
                            <span class="px-2">
                              {{ userlist && userlist[v] && userlist[v].name }}
                            </span>
                          </div>
                        </div>
                      </template>
                      <div v-else class="grey--text">No Data</div>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <div class="">
                      <span class="body-2 font-weight-bold text-sm" style="font-size:12px !important">Approver(s)</span>
                      <template v-if="(viewtaskdata.approver || []).length > 0">
                        <div class="border-on  radius-small">
                          <div v-for="(v, k) in viewtaskdata.approver" :key="k" class=" py-2 radius-small"
                            style="border-bottom:1px solid #D9DDE4">
                            <span class="px-2">
                              {{ userlist && userlist[v] && userlist[v].name }}
                            </span>
                          </div>
                        </div>
                      </template>
                      <div v-else class="grey--text">No Data</div>
                    </div>
                  </v-col>
                </v-row>
                <!-- Purpose and instruction -->
                <v-row>
                  <v-col cols="6">
                    <span class="body-2 font-weight-bold text-sm" style="font-size:12px !important">Purpose</span>

                    <div v-if="viewtaskdata.purpose" class="border-on pa-2 radius-small">{{ viewtaskdata.purpose }}
                    </div>
                    <div v-else class="grey--text   ">No Data</div>
                  </v-col>
                  <v-col cols="6">
                    <span class="body-2 font-weight-bold text-sm" style="font-size:12px !important">Instruction</span>
                    <div v-if="viewtaskdata.instruction" class="border-on pa-2 radius-small">{{ viewtaskdata.instruction
                      }}</div>
                    <div v-else class=" grey--text ">No Data</div>
                  </v-col>
                </v-row>
              </v-col>
              <!-- assertions and iwrl -->
              <v-row cols="12" class="">
                <v-col>
                  <div class="font-weight-bold px-3 py-1">
                    <div class="d-flex align-center">
                      <span class="body-2 font-weight-bold text-sm" style="font-size:12px !important">Assertions</span>
                      <v-spacer></v-spacer>
                    </div>
                  </div>
                  <div class="mx-2 mb-2" v-if="(viewtaskdata.assertions || []).length > 0">
                    <v-chip v-for="(va, ka) in viewtaskdata.assertions" :key="ka" small outlined
                      class="pl-2  mr-2 mb-2">
                      <span class="pl-1">{{ va }}</span>
                    </v-chip>
                  </div>
                  <div v-else class="grey--text px-3 mb-2">No Data</div>
                </v-col>
                <v-col>
                  <div class=" ">
                    <span class="body-2 font-weight-bold text-sm" style="font-size:12px !important">Information Workflow
                      Request
                      List</span>
                    <!-- <v-spacer></v-spacer> -->
                    <div class=" ">
                      <div v-if="(pbclinkdialogitems || []).length > 0 && ifInformationdata" class="border-on  radius-on">
                        <div v-for="(v,k) in pbclinkdialogitems" :key="k" class="hoverable mx-2 ">
                          <div class="d-flex align-start py-1 px-2">
                            <span class="mr-1">
                              <v-tooltip left content-class="tooltip-left" v-if="v.stage === -1">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on" color="">mdi-cancel</v-icon>
                                </template>
                                <span>Cancelled Request</span>
                              </v-tooltip>
                              <v-tooltip left content-class="tooltip-left" v-else-if="v.stage === 0">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on" color="info">mdi-timer-sand</v-icon>
                                </template>
                                <span>Waiting Response</span>
                              </v-tooltip>
                              <v-tooltip left content-class="tooltip-left" v-else-if="v.stage === 1">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on" color="warning">mdi-timer-sand</v-icon>
                                </template>
                                <span>Pending Approval</span>
                              </v-tooltip>
                              <v-tooltip left content-class="tooltip-left" v-else-if="v.stage === 2">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on" color="success">mdi-check-circle</v-icon>
                                </template>
                                <span>Approved Request</span>
                              </v-tooltip>
                            </span>
                            <div class="" v-if="v.selectlist.length===0">
                            <span>{{v?.description}}</span>
                            </div>
                            <div v-else>
                              <template v-for="(item, index) in v.selectlist" >
                                <span v-if="item.location === viewtaskdata.location" :key="index">{{v.description}}</span>
                              </template>
                            </div>
                            <v-spacer></v-spacer>
                          </div>
                        </div>
                      </div>
                      <div v-else class="grey--text ">No Data</div>
                    </div>

                  </div>
                </v-col>
              </v-row>
              <!--  -->
            </v-row>
          </div>
        </template>
        <template v-slot:actions>
          <div class="d-flex align-center justify-center " style="width: 100%; gap:20px">
            <div class="d-flex flex-column align-center justify-center"
              v-if="viewtaskdata.approver?.includes(userid) && metadata.stage === 0">
              <v-btn icon small @click.stop="changeChecklistStatus(viewtaskdata._id, 'approve')"> <v-icon
                  color="green">mdi-check-circle</v-icon></v-btn>
              <span>Approve</span>
            </div>
            <div class="d-flex flex-column align-center justify-center"
              v-if="viewtaskdata.approver?.includes(userid) && metadata.stage === 0">
              <v-btn icon small @click="changeChecklistStatus(viewtaskdata._id, 'reject')"><v-icon
                  color="#B32318">mdi-close-circle
                </v-icon></v-btn>
              <span>Decline</span>
            </div>
            <div class="d-flex flex-column align-center justify-center"
              v-if="viewtaskdata.preparer?.includes(userid) && metadata.stage === 0">
              <v-btn icon small @click.stop="opensendForReviewDialog(viewtaskdata)"><img
                  src="/img/icons/SubmitIcon.svg" /></v-btn>
              <span>Submit</span>
            </div>
            <div class="d-flex flex-column align-center justify-center" v-if="fileNamesList.length>0">
              <v-btn icon small @click="$nova.downloadFiles(viewtaskdata.upload)"><img
                  src="/img/icons/DownloadGreen.svg" /></v-btn>
              <span>Download</span>
            </div>

            <div class="d-flex flex-column mt-2 align-center justify-center">

              <NewConversation heading="NewConversation" width="600" :recurring="false" :recurringText="''"
                :closed="metadata.stage !== 0" @markread="viewtaskdata.conversation_read = true"
                :notify="!viewtaskdata.conversation_read"
                :getapi="'/v2/financialclose/analysis/getconversation/' + metadata._id + '/c/' + viewtaskdata._id"
                :sendapi="'/v2/financialclose/analysis/addconversation/' + metadata._id + '/c/' + viewtaskdata._id"
                :readapi="'/v2/financialclose/analysis/markreadconversation/' + metadata._id + '/c/' + viewtaskdata._id"
                :mentionapi="'/v2/financialclose/analysis/getusersformdata'" />

              <span class="mt-1">Comment</span>
            </div>
            <v-divider vertical></v-divider>

            <div class="d-flex flex-column align-center justify-center">
              <v-btn icon small @click="openHelpdeskDialog(viewtaskdata)">
                <img src="/img/icons/VirtualConcierge.svg" width="15px" height="15px" />
              </v-btn>
              <span>Support</span>
            </div>

            <div class="d-flex flex-column align-center justify-center"
              v-if="viewtaskdata.botid && Object.keys(viewtaskdata.result).length === 0">
              <v-btn icon small @click="unlinkBot(viewtaskdata)" color="caccounting">
                <img src="/img/icons/LinkBot.svg" width="15px" height="15px" />
              </v-btn>
              <span>Unlink Bot</span>
            </div>

            <div class="d-flex flex-column align-center justify-center"
              v-else-if="Object.keys(viewtaskdata.result).length !== 0">
              <v-btn icon small color="green" @click="unlinkBot(viewtaskdata)">
                <img src="/img/icons/LinkBot.svg" width="15px" height="15px" />
              </v-btn>
              <span>Bot results ready</span>
            </div>
            <div class="d-flex flex-column align-center justify-center" v-else>
              <v-btn icon small @click="openlinkBotDialog(viewtaskdata)">
                <img src="/img/icons/LinkBot.svg" width="15px" height="15px" />
              </v-btn>
              <span>Link Bot</span>
            </div>

            <div class="d-flex flex-column align-center justify-center"
              v-if="metadata.stage !== 3 && viewtaskdata.stage !== 3 && metadata.managerlist.indexOf(userid) > -1">
              <v-btn icon small @click="addEditForm(viewtaskdata)"><v-icon>mdi-pencil</v-icon></v-btn>
              <span>Edit</span>
            </div>
            <div class="d-flex flex-column align-center justify-center"
              v-if="metadata.stage !== 3 && metadata.managerlist.indexOf(userid) > -1">
              <v-btn icon small
                @click="deletedialog = true; deleteitem = viewtaskdata;"><v-icon>mdi-delete</v-icon></v-btn>
              <span>Delete</span>
            </div>

            <div class="d-flex flex-column align-center justify-center">
              <span>
                <lb-activitylognew :config="activitylogconfig"
                  :url="`/v2/financialclose/analysis/getlogs/${metadata._id}/c/${viewtaskdata._id}`" />
              </span>
              <span>Activity Log</span>
            </div>
          </div>
        </template>
      </lb-dailogboxnew>

      <lb-dailogboxnew v-model="selectgldialog" heading="Select GL(s) to update" width="600" persistent>
        <template v-slot:body>
          <div>
            <div class="mb-2">
              <lb-string label="Search" v-model="searchcustomfilter" hidedetails />
            </div>
            <!-- <template v-for="(v, k) in tb" :key="k">
              <div :key="k"
                v-if="(v.glcode + v.name).replaceAll(' ', '').toLowerCase().indexOf(searchcustom.toLowerCase()) > -1"> -->
            <div class="align-center mb-3 mt-3">
              <v-simple-table dense class="FC-Table" v-if="filteredItems.length > 0">
                <thead>
                  <tr>
                    <th scope="Name"></th>
                    <th scope="columns">GL code</th>
                    <th scope="name">Name</th>
                    <th scope="ref">Ref</th>
                    <th scope="location">Location</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(v, k) in filteredItems">
                    <tr :key="k">
                      <td><v-checkbox class="mt-0" dense label="" v-model="selectgls" multiple :value="v._id"
                          hide-details></v-checkbox></td>
                      <td>{{ v.glcode }}</td>
                      <td>{{ v.name }}</td>
                      <td>
                        <div v-for="(gg, jj) in v.reference_detail || [] " :key="jj">
                          <span>{{ gg.ref }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-for="(vv, kk) in v.location_detail || [] " :key="kk">
                          <span class="font-weight-black ml-2">{{ vv.name }}</span>
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </v-simple-table>
            </div>
            <v-divider></v-divider>
          </div>
        </template>
        <template v-slot:actions>
          <v-spacer></v-spacer>
          <v-btn small class="ml-2" color="fbcPrimary" @click="addSelectedGls()">Select</v-btn>
        </template>
      </lb-dailogboxnew>
      <!-- task workpaper upload dailog box -->
      <lb-dailogboxnew v-model="sendforreviewdialog" heading="Send task for review" width="500" persistent
        :loading="sendforreviewloading">
        <template v-slot:tabs>
          <v-tabs style="margin-right: 50px; margin-left:50px;" class="py-3" hide-slider>
            <v-tab @click="uploadboxtab = 1" :class="{ 'grey-background': uploadboxtab === 1 }" class="border-on"
              style="border-radius: 8px 0px 0px 8px;">Upload Work Paper
            </v-tab>
            <v-tab @click="uploadboxtab = 2" :class="{ 'grey-background': uploadboxtab === 2 }" class="border-on"
              style="border-radius: 0px 8px 8px 0px;" color="fbcPrimary">
              Add Remark & Submit
            </v-tab>
          </v-tabs>

        </template>

        <template v-slot:body>
          <div class="" v-if="uploadboxtab === 1">
            <div class="dragdropmain">
              <div class="dropzone-container" @dragover="dragover" @dragleave="dragleave" @drop="drop" @click="onChange" @change="onChange">
                  <input type="file" multiple  name="file" id="fileInput" class="hidden-input" @click="onChange" v-on:change="onChange" ref="file"
                  accept=".xlsx,.xls,.xlsm,.xlsb,.doc,.docx,.ppt,.pptx,.pdf" />
                  <label for="fileInput" class="file-label">
                      <div v-if="isDragging">Release to drop files here.</div>
                      <div v-else class="file-label" style="font-size: 15px !important; color: #667085;">
                        <span v-if="files.length > 0">{{files.map(file => file.name).join(', ')}}</span>
                        <span v-else> 
                          Drag and drop file single here
                          <br/>
                          or
                          <br/>
                          Click to add files
                        </span>
                        </div>
                  </label>
              </div>
          </div>
            <div class="mt-4">
              <div class="text-center mb-4">Upload the work paper template here</div>
              <div class="d-flex align-center justify-center ga-2 ">
                <div class="w-20">
                  <v-btn class="p-2 py-4  rounded-lg caccounting--text"
                    style="border-radius: 8px !important; border:2px solid #6A67CE" small
                    @click="workpaperTemplate()">Download
                    Template</v-btn>
                </div>
                <div style="width:12vh">
                  <v-btn color="caccounting" class="white--text ml-3 px-6" style="border-radius:8px !important"
                    @click="uploadFiles(sendforreviewdata)">Upload</v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="text-left px-10 full-width full-height" v-else>
            <span class="font-weight-bold">Remarks</span>
            <template>
              <div class="text-field">
                <textarea class="input" rows="4" cols="50" placeholder="Type the remarks here"
                  v-model="sendforreviewremark"></textarea>
              </div>
            </template>
            <div class="full-width d-flex align-center justify-center mt-4 mb-5 rounded-small"
              :class="{ 'active': isActive }">
              <v-btn color="caccounting " @click="sendForReview()" class="white--text px-3 mt-4 "
                style="border-radius:8px !important ; width:80px !important">
                <v-icon small :left="!icononly" class="pl-2">mdi-upload</v-icon>
                <span class="pr-2">Submit</span>
              </v-btn>
            </div>
          </div>
        </template>
        <template v-slot:action v-if="sendforreview">
        </template>
      </lb-dailogboxnew>
      <!-- Bulk file upload of selected task -->
      <lb-dailogboxnew v-model="sendforreviewdialogbulk" heading="Send bulk task for review" width="500" persistent
        :loading="sendforreviewloadingbulk">
        <template v-slot:tabs>
          <v-tabs style="margin-right: 50px; margin-left:50px;" hide-slider>
            <v-tab @click="uploadboxtab = 1" :class="{ 'grey-background': uploadboxtab === 1 }" class="border-on"
              style="border-radius: 8px 0px 0px 8px;">Upload Work Paper
            </v-tab>
            <v-tab @click="uploadboxtab = 2" :class="{ 'grey-background': uploadboxtab === 2 }" class="border-on"
              style="border-radius: 0px 8px 8px 0px;" color="fbcPrimary">
              Add Remark & Submit
            </v-tab>
          </v-tabs>

        </template>
        <template v-slot:body>
          <div class="" v-if="uploadboxtab === 1">
            <div class="dragdropmain">
              <div class="dropzone-container" @dragover="dragover" @dragleave="dragleave" @drop="drop" @click="onChange" @change="onChange">
                  <input type="file" multiple  name="file" id="fileInput" class="hidden-input" @click="onChange" v-on:change="onChange"  ref="file"
                  accept=".xlsx,.xls,.xlsm,.xlsb,.doc,.docx,.ppt,.pptx,.pdf" />
                  <label for="fileInput" class="file-label">
                      <div v-if="isDragging">Release to drop files here.</div>
                      <div v-else class="file-label" style="font-size: 15px !important; color: #667085;">
                        <span v-if="files.length > 0">{{files.map(file => file.name).join(', ')}}</span>
                        <span v-else> 
                          Drag and drop file single here
                          <br/>
                          or
                          <br/>
                          Click to add files
                        </span>
                      </div>
                  </label>
              </div>
          </div>
            <div class="mt-4">
              <div class="text-center mb-4">Upload the work paper template here</div>
              <div class="d-flex align-center justify-center ga-2 ">
                <div class="w-20">
                  <v-btn class="p-2 py-4  rounded-lg caccounting--text"
                    style="border-radius: 8px !important; border:2px solid #6A67CE" small
                    @click="workpaperTemplate()">Download
                    Template</v-btn>
                </div>
                <div style="width:12vh">
                  <v-btn color="caccounting" class="white--text ml-3 px-6" style="border-radius:8px !important"
                    @click="uploadFilesBulk( checkedTaskdetails)">Upload</v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="text-left px-10 full-width full-height" v-else>
            <span class="font-weight-bold">Remarks</span>
            <template>
              <div class="text-field">
                <textarea class="input" rows="4" cols="50" placeholder="Type the remarks here"
                  v-model="textFieldContent"></textarea>
              </div>
            </template>
            <div class="full-width d-flex align-center justify-center mt-4 mb-5 rounded-small"
              :class="{ 'active': isActive }">
              <v-btn color="caccounting " @click="sendForReview()" class="white--text px-3 mt-4 "
                style="border-radius:8px !important ; width:80px !important">
                <v-icon small :left="!icononly" class="pl-2">mdi-upload</v-icon>
                <span class="pr-2">Submit</span>
              </v-btn>
            </div>
          </div>
        </template>
        <template v-slot:action v-if="sendforreview">
        </template>
      </lb-dailogboxnew>
      <!-- Link bot dailog box -->
      <lb-dailogboxnew v-model="linkbotdialog" heading="Select a bot to link" width="600" persistent
        :loading="linkbotloading">
        <template v-slot:body>
          <v-container class="pa-0">
            <v-list lines="three" class="py-0" style="width:99%">
              <v-list-item v-for="bot in botlist" :key="bot.value" :value="bot.value">
                <div class="d-flex border-on pa-1 my-1 radius-small cursor-pointer"
                  :class="{ 'selected-item': selectedbot === bot.value }" @click="selectedbot = bot.value">
                  <v-checkbox :ripple="false" hide-details class="mt-2 mx-0 pa-0"
                    :input-value="selectedbot === bot.value ? true : false"
                    :color="selectedbot === bot.value && '#7F56D9'"></v-checkbox>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">{{ bot.name }}</v-list-item-title>
                    <v-list-item-subtitle class="clip-text">
                      {{ bot.description }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </div>
              </v-list-item>
            </v-list>

          </v-container>
          <div v-if="selectedbot === '6470e55d999d5833d9d58224'">
            <div class="mb-2"><strong>Select Bot Result(s):</strong></div>
            <v-checkbox v-for="lease in leaseScheduleItems || []" :key="lease" :label="lease" dense
              class="d-inline-block ml-2" :value="lease" v-model="leaseScheduleValue" multiple color="#7F56D9"
              hide-details></v-checkbox>
          </div>
          <div class=" mt-3">
            Need additional bots?
            <span @click="$nova.gotoLink({ path: '/financialclose/functionalsupport/' + metadata._id })"
              class="cursor-pointer text-decoration-underline blue--text">Contact us</span>
          </div>
        </template>

        <template v-slot:actions>
          <v-spacer></v-spacer>
          <v-btn class="ml-2 white--text radius-small" style="border-radius: 8px !important;" color="caccounting"
            @click="linkBot()">
            <img src="/img/icons/UploadICon.svg" alt="" width="15px" height="15px" />
            <span class="px-2"> Link</span>
          </v-btn>
          <v-spacer></v-spacer>

        </template>
      </lb-dailogboxnew>

      <lb-dailogboxnew v-model="bulklinkbotdialog" heading="Select a bot to bulk link" width="500" persistent
        :loading="bulklinkbotloading">
        <template v-slot:body>

          <div class="text-center">
            <div class="d-flex align-center justify-center">
              <lb-dropdown v-model="selectedbot" label="Select Bot*" :items="botlist"></lb-dropdown>
            </div>
          </div>
          <div class="mt-2 mb-2" v-if="selectedbot === '6470e55d999d5833d9d58224'"><strong>Select Bot
              Result(s):</strong></div>
          <div v-for="(task, i) in addeditdata" :key="i">
            <div class="mb-5">
              <strong>Task:{{ getTaskDeatils(task).ref }}</strong>
            </div>
            <div v-if="selectedbot === '6470e55d999d5833d9d58224'" class="mb-3">
              <v-checkbox v-for="lease in leaseScheduleItems || []" :key="lease" :label="lease" dense
                class="d-inline-block ml-2" :value="lease" v-model="leaseSchedulebulkValue[getTaskDeatils(task)._id]"
                multiple hide-details></v-checkbox>
            </div>
          </div>
          <div class="mt-4 ">
            Need additional bots? <v-btn text class="pa-0 ma-0 shadow-off blue--text"   height="1vh" @click="$nova.gotoLink({ path: '/financialclose/functionalsupport/' + metadata._id })">Contact Us</v-btn>   </div>
        </template>
        <template v-slot:actions v-if="sendforreview">
          <v-spacer></v-spacer>
          <v-btn small class="ml-2 white--text" color="caccounting" style="border-radius:8px !important "
            @click="bulklinkBot()">Link</v-btn>
          <v-spacer></v-spacer>
        </template>
      </lb-dailogboxnew>

      <lb-dailogboxnew v-model="NotavailablepopTb" heading="Trial balance not available" width="350" persistent>
        <template v-slot:body>
          <div class="text-6 font-weight-bold" v-if="addeditdata.location != ''">
            Trial balance/Chart of accounts not available to retrieve General Ledger information
          </div>
          <div class="text-6 font-weight-bold" v-else>
            Please select Location for getting Trail Balance data/Chart of accounts
          </div>
        </template>
        <!-- <template v-slot:actions v-if="sendforreview">
          <v-spacer></v-spacer>
          <v-btn small class="ml-2" color="primary" >Link</v-btn>
        </template> -->
      </lb-dailogboxnew>

    </div>
  </div>
</template>

<script>
let styledxlsx = require('sheetjs-style');
// import pbc from '../../../pbc/views/ProjectView';
import BulkUpdloadCheckListNew from '../Components/BulkUpdloadCheckListNew.vue';
import NewConversation from "../../../../views/common/NewConversation.vue";
// import bulkChecklistUpload from './Components/BulkUploadChecklist';

export default {
  name: 'financialclose_project_checklist',
  // props: ['props'],
  data: function () {
    return {
      metadata: {},
      tb: [],
      pbc: [],
      tbjson: {},
      checklist: [],
      reflist: [],
      summary: {},
      pbcdata: {},
      formattedchecklistdata: [],
      // pbc: {},
      fileprocessing: {},
      fileprocessingpercent: {},
      stagefilter: [0, 1, 2, 3],
      userid: "",
      multiselectdata: [],
      commentsdialog: false,
      commentsloading: false,
      conversations: [],
      conversationtext: "",
      conversationtexterror: "",
      viewtaskdata: {},
      viewtaskdialog: false,
      editkey: -1,
      addeditdata: {},
      addeditdataerror: {},
      addeditdialog: false,
      addeditloading: false,
      deletedialog: false,
      deleteloading: false,
      deleteitem: {},
      helpdeskdialog: false,
      helpdeskloading: false,
      helpdeskitemnew: false,
      helpdeskitem: {},
      formdata: {},
      eligibleusers: {},
      userlist: {},
      locationlist: {},
      stage: -1,
      files: [],
      filesbulk: [],
      id: "",
      clientId: "",
      loading: false,
      counter: 0,
      alltaskassigned: true,
      alltaskopen: true,
      tblinkdialog: false,
      tblinkdialogitems: [],
      tblinkdialogchecklistdata: {},
      schedulelinkdialog: false,
      schedulelinkdialogitems: [],
      schedulelinkdialogchecklistdata: {},
      pbclinkdialog: false,
      pbclinkdialogitems: [],
      pbclinkdialogchecklistdata: {},
      possibleassertions: ['Existence', 'Occurrence', 'Rights and obligations', 'Completeness', 'Accuracy valuation and allocation', 'Cut–off', 'Classification', 'Presentation'],
      activitylogconfig: {
        checklistedit: { icon: "mdi-pencil", color: "fbcPrimary", displaytext: "Checklist Updated", src: "/img/icons/PenIcon.svg" },
        bulkapprove: { icon: "mdi-tag-check-outline ", color: "fbcPrimary", displaytext: "Bulk Approve", src: "/img/icons/CheckCircle.svg" },
        bulkreject: { icon: "mdi-close-box-multiple-outline  ", color: "fbcPrimary", displaytext: "Bulk Reject", src: "/img/icons/CancleCircle.svg" },
        checklistsentforreview: { icon: "mdi-file-eye", color: "fbcPrimary", displaytext: "Checklist Sent For Review", src: "/img/icons/FileArrowUp.svg" },
        fileupload: { icon: "mdi-file-upload", color: "fbcPrimary", displaytext: "File Upload", src: "/img/icons/UploadActionClous.svg" },
        checklistbulkadd: { icon: "mdi-file-upload", color: "fbcPrimary", displaytext: "File Upload", src: "/img/icons/FileArrowUp.svg" },
        checklistcreate: { icon: "mdi-file-upload", color: "fbcPrimary", displaytext: "File Upload", src: "/img/icons/FileArrowUp.svg" }
      },
      selectgldialog: false,
      searchcustom: "",
      searchcustomfilter: "",
      selectgls: [],
      selectedGlCodes: [],
      sendforreviewloading: false,
      sendforreviewloadingbulk: false,
      sendforreviewdialog: false,
      sendforreviewdialogbulk: false,
      sendforreview: true,
      sendforreviewdata: {},
      sendforreviewremark: "",
      linkbotdialog: false,
      bulklinkbotdialog: false,
      selectedbot: "",
      linkbotloading: false,
      bulklinkbotloading: false,
      botlist: [
        { name: 'Lease Schedule', value: '6470e55d999d5833d9d58224', description: 'An AI leasing bot, or a real estate chatbot, is an AI-driven virtual assistant designed to interact with prospective tenants automatically.' },
        { name: 'Borrowing Schedule', value: '6474cf6a1117a96cfd35795d', description: 'An AI leasing bot, or a real estate chatbot, is an AI-driven virtual assistant designed to interact with prospective tenants automatically.' },
        { name: 'Transaction Matching', value: '6474e49c00b1f12f6a2a8065', description: 'An AI leasing bot, or a real estate chatbot, is an AI-driven virtual assistant designed to interact with prospective tenants automatically.' },
      ],
      possibleusers: [],
      bulkactionuserdialog: false,
      bulkactionuserid: '',
      bulkactionuserloading: false,
      bulkactionuserusertype: '',
      bulkactionuserselected: false,
      isLocation: false,
      isLocationSelected: false,
      selectaction: 'add',
      changetouser: '',
      itemapprover: [
        { value: "associate", name: "Add" },
        { value: "remove", name: "Remove" },
        { value: "replace", name: "Replace" },
        { value: "move", name: "Change to Approver" }
      ],
      itempreparer: [
        { value: "associate", name: "Add" },
        { value: "remove", name: "Remove" },
        { value: "replace", name: "Replace" },
        { value: "move", name: "Change to Preparer" }
      ],
      leaseScheduleValue: [],
      leaseSchedulebulkValue: {},
      leaseScheduleItems: [
        "BS-ROU Asset",
        "BS-Lease Liability",
        "PL-Amortization Cost",
        "PL-Interest Expense",
        "BS-Security Deposit",
        "BS-SD-Interest Income"
      ],
      NotavailablepopTb: false,
      dataArray: [],
      bulkfilesloading: 1,
      filterList: [],
      filterListitems: [],
      filterSelectedData: { "selectedlocation": [], "selectedTaskType": [""] },
      userSearch: "",
      locationSearch: "",
      defaultGlCodes: [],
      selectAllCheckbox: false,
      multiselectfileds: {},
      selectedusertype: 2,
      uploadboxtab: 1,
      currentPage: 1,
      itemsPerPage: 14,
      paginatedList: [],
      wholeChecklist: [],
      hover: '',
      TaskStage: -1,
      typeOfTask: ['Closed Tasks', "Pending Submissions", "Pending Approvals"],
      selectedFilters: [], 
      selectedFilter: 'Total Tasks',
      infomangerrequests:[],
      ifInformationdata:false,
      inforeqstage:0,
      clearuploadfiledata:false,
      fileNamesList:[],
      isDragging: false, 
      checkedTaskdetails:[]
    }
  },
  components: {
    // pbc,
    BulkUpdloadCheckListNew, NewConversation
  },
  created() {
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },

  // mounted() {
  //   this.updatePaginatedList();
  // },
  methods: {
    filters() {
      this.filterList = [...this.formattedchecklistdata];

      if (this.filterSelectedData.selecteduser !== "All" && this.filterSelectedData.selecteduser !== undefined) {
        let filteredList = [];
        let selectedUser = this.filterSelectedData.selecteduser;
        this.filterList.forEach(x => {
          let users = [...(x.preparer || []), ...(x.approver || [])];
          if (users.includes(selectedUser)) {
            filteredList.push(x);
          }
        });
        this.filterList = filteredList;
      }
      else if(this.TaskStage.length>0)
      {
       let list = this.filterList.filter(x => this.TaskStage.includes(x.stage) );
        this.filterList = list
      }
      else if(this.filterSelectedData.selectedlocation.length>0){
        this.filterList = this.filterList.filter(x => this.filterSelectedData.selectedlocation.includes(x.locationName));
      }
      else {
        this.filterList = [...this.formattedchecklistdata];
      }
      console.log("filtered list", this.filterList);
    },
    filterByApprover() {
      this.selectedusertype = 1;
      if (this.TaskStage !== -1) {
        let list = this.wholeChecklist.filter(x =>
          (x.approver || []).indexOf(this.userid) > -1 || (x.type && x.name)
        );
        this.checklist = list.filter(x => this.TaskStage.includes(x.stage))
      }
      else {
        this.checklist = this.wholeChecklist.filter(x =>
          (x.approver || []).indexOf(this.userid) > -1 || (x.type && x.name)
        );
      }
      this.resetFilter()
    },
    filterByPreparer() {
      this.selectedusertype = 2;
      if (this.TaskStage !== -1) {
        let list = this.wholeChecklist.filter(x =>
          (x.preparer || []).indexOf(this.userid) > -1 || (x.type && x.name)
        );
        this.checklist = list.filter(x => this.TaskStage.includes(x.stage))
      }
      else {
        this.checklist = this.wholeChecklist.filter(x =>
          (x.preparer || []).indexOf(this.userid) > -1 || (x.type && x.name)
        );
      }
      this.resetFilter()
    },
    toggleSelectAll() {
      if (this.metadata.stage === 0) {
        if (this.selectAllCheckbox) {
          this.multiselectdata = this.filterList.filter(item => item.type !== 'category').map(item => item._id);
          this.updateCategorySelectionsState();
        } else {
          this.multiselectdata = [];
          this.multiselectfileds = {};
        }
      }
    },

    checkCategoryCheckbox(categoryName) {
      const categoryItems = this.filterList
        .filter(item => item.type !== 'category' && item.category === categoryName)
        .map(item => item._id);
      const otherSelectedItems = this.multiselectdata
        .filter(id => !categoryItems.includes(id));

      if (categoryName in this.multiselectfileds) {
        delete this.multiselectfileds[categoryName];
        this.multiselectdata = otherSelectedItems;
      } else {
        this.multiselectfileds = { ...this.multiselectfileds, [categoryName]: categoryItems };
        this.multiselectdata = [...new Set([...otherSelectedItems, ...categoryItems])];
      }
      this.updateSelectAllState();
    },
    toggleItemSelection(itemId) {
      const index = this.multiselectdata.indexOf(itemId);
      if (index === -1) {
        this.multiselectdata.push(itemId);
      } else {
        this.multiselectdata.splice(index, 1);
      }
      this.updateCategorySelectionsState();
      this.updateSelectAllState();
    },

    updateCategorySelectionsState() {
      const categories = {};
      this.filterList.forEach(item => {
        if (item.type !== 'category' && this.multiselectdata.includes(item._id)) {
          if (!categories[item.category]) {
            categories[item.category] = [];
          }
          categories[item.category].push(item._id);
        }
      });

      Object.entries(categories).forEach(([category, items]) => {
        const allCategoryItems = this.filterList
          .filter(item => item.type !== 'category' && item.category === category)
          .map(item => item._id);

        if (allCategoryItems.every(id => items.includes(id))) {
          this.multiselectfileds[category] = items;
        } else {
          delete this.multiselectfileds[category];
        }
      });
    },

  
  updateSelectAllState() {
    const allSelectableItems = this.filterList
      .filter(item => item.type !== 'category')
      .map(item => item._id);

    this.selectAllCheckbox = 
      allSelectableItems.length > 0 && 
      allSelectableItems.every(id => this.multiselectdata.includes(id));
  },
 

    updatePaginatedList() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      this.paginatedList = this.filterList.slice(start, end);
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    reset() {
      this.filterSelectedData.selectedlocation = ''
      this.filterSelectedData.selectedTaskType = ''
      this.TaskStage = -1
      this.selectedFilters=[]
    },
    resetFilter() {
      this.filterSelectedData.selecteduser = 'All'
      this.filterList = this.formattedchecklistdata;
      this.currentPage = 1;
      this.multiselectdata = []
      this.selectAllCheckbox = false;
    },
    filterWithLocation(location) {
      this.filterSelectedData.selectedlocation = location;
      this.filters();
    },
    filterWithuser(user) {
      this.filterSelectedData.selecteduser = user;
      this.filters();
    },

    selectTypeOfTask(e){
      if(e.length === 0){
        this.TaskStage= -1,
        this.filterSelectedData.selectedTaskType = [""];
        this.checklist=this.wholeChecklist
        
      }
    },
    getTaskStage(filters) {
      let stages ='';
      if (filters.includes('Total Tasks')) {
        return null; // No specific stage for Total Tasks
      }
      // If only one filter is selected, return its stage
      if (filters.length === 1) {
        switch (filters[0]) {
          case 'Closed Tasks':
            return [3];
          case 'Pending Submissions':
            return [0];
          case 'Pending Approvals':
            return [2];
          default:
            return null;
        }
      }
      else
      {
         stages = filters.map(filter => {
        switch (filter) {
          case 'Closed Tasks':
            return 3;
          case 'Pending Submissions':
            return 0;
          case 'Pending Approvals':
            return 2;
          default:
            return null;
        }
      });
      }
      return stages;
    },

    filterformated() {
      let locations = new Set(['']);
      let userlists = new Set(['All']);
      const filteredData = this.formattedchecklistdata.map(item => {
        if (item.locationName) locations.add(item.locationName);
        if (item.preparer) item.preparer.forEach(user => userlists.add(user));
        if (item.approver) item.approver.forEach(user => userlists.add(user));
        return {
          _id: item._id,
          approver: item.approver,
          locationName: item.locationName,
          preparer: item.preparer,
        };
      });
      this.filterListitems = {
        locations: [...Array.from(locations).filter(location => location)],
        userlists: [...Array.from(userlists)]
      };
      this.filterList = filteredData;
      this.filters();
    },
    refreshData() {
      this.id = this.$route.params.id || "";
      this.userid = this.$store.state.user.id;
      this.clientId = this.$store.state.client;
      console.log(this.$store)
      this.multiselectdata = [];
      this.files=[];
      this.filesbulk=[];
      this.isDragging=false,
      this.getData();
      this.selectedbot = "";
    },
    viewTask(dt = {}) {
      this.inforeqstage=0;
      this.pbclinkdialogitems=[];
      console.log(dt);
      this.viewtaskdata = {...dt};
      this.fileNamesList=[]
      if(dt.stage ===2 || dt.stage===3){
      this.axios.post("/v2/upload/filenames/"+this.viewtaskdata.upload).then(dt => {
        this.fileNamesList = dt.data.data
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      })
      }
      if (this.infomangerrequests.some(item => item?.selectlist?.length > 0)) {
        this.ifInformationdata = this.infomangerrequests.some(item =>
          item?.selectlist?.some(subItem => {
            return subItem?.location === dt.location;
          })
        );
      } else {
        this.ifInformationdata = true;
      }
      this.populatepbclinkdialogitems(dt, false);
      this.populatetblinkdialogitems(dt, false);
      this.populateschedulelinkdialogitems(dt, false);
      this.viewtaskdata["isMatched"]= Math.abs((this.tblinkdialogchecklistdata.__tbtotals?.total - Number(this.schedulelinkdialogchecklistdata.result.value?.total_value) || this.tblinkdialogchecklistdata.__tbtotals?.total || Number(this.schedulelinkdialogchecklistdata.result.value?.total_value) || 0))< dt.threshold ? true:false;
      this.infomanagerReq(dt);
      this.viewtaskdialog = true;
    },
    infomanagerReq(dt){
      this.pbclinkdialogitems = this.infomangerrequests.filter((item) => {
      const searchId = dt.master_id;
      const selectlistMatch = item.selectlist.filter(subItem => subItem.selectlist === searchId  && subItem.location === dt.location);
      const referenceMatch = item.reference.includes(dt.id);
      return selectlistMatch.length >0 ? selectlistMatch : referenceMatch
    });

      this.pbclinkdialogitems.forEach((item)=>{
        item.stage===2 && this.inforeqstage++
      })
      let progress = (this.inforeqstage/this.pbclinkdialogitems.length )*100
      this.inforeqstage= Math.round(progress)
    },
    deleteCycleTask(){
      this.deletedialog=true; 
      this.deleteitem=this.viewtaskdata
    },
    openlinkBotDialog: function (d) {
      this.addeditdata = { ...d };
      this.selectedbot = this.addeditdata.botid;
      this.linkbotdialog = true;
      this.leaseScheduleValue = [];
    },
    bulkopenlinkBotDialog: function (d) {
      this.addeditdata = { ...d };
      this.selectedbot = this.addeditdata.botid;
      this.bulklinkbotdialog = true;
      this.leaseScheduleValue = [];
    },
    opensendForReviewDialog: function (d) {
      this.sendforreviewdata = { ...d };
      this.sendforreviewremark = this.sendforreviewdata.remark;
      this.sendforreview = false;
      this.sendforreviewdialog = true;
    },
    openSelectGlDialog: function () {
      this.selectgls = [];
      this.searchcustom = "";
      this.searchcustomfilter = "";
      this.filteredItems;
      if (Object.keys(this.addeditdata).includes("__tbtotals")) {
        for (const i of this.addeditdata.__tbtotals.items) {
          this.selectgls.push(i._id);
        }
      }
      this.selectgldialog = true;
    },
    addSelectedGls: function () {
      let tbtotals = { total: 0, items: [] };
      this.selectedGlCodes = []
      for (const i of this.selectgls || []) {
        this.selectedGlCodes.push(this.filteredItems.filter(item => item._id === i));
      }
      for (const i of this.selectgls || []) {
        tbtotals.items.push(this.tbjson[i]);
        tbtotals.total = tbtotals.total + i[0].closing_amount || 0;
      }
      this.addeditdata.__tbtotals = tbtotals;
      this.selectgldialog = false;
    },
    addEditForm(dt = {}) {
      console.log(dt);
      this.addeditdataerror = {};
      this.addeditdata = { ...dt };
      console.log(JSON.stringify(this.addeditdata))
      this.selectedGlCodes = []
      this.defaultGlCodes = this.addeditdata.glcode ?? [];
      for (const i of this.addeditdata.__tbtotals?.items || []) {
        console.log(JSON.stringify(i.glcode) + "selected gls")
        this.selectedGlCodes.push(this.filteredItems.filter(item => item._id === i._id));
      }
      //this.addeditdata.location = "64510941c50ca87e50dcec675";
      this.isLocationSelected = false;
      this.addeditdialog = true;
    },
    addEditChecklistItem() {
      this.addeditloading = true;
      let type = "add";
      let url = "/v2/financialclose/analysis/addchecklist/" + this.id;
      if (this.addeditdata._id) {
        type = "edit";
        url = "/v2/financialclose/analysis/editchecklist/" + this.addeditdata._id;
      }
      let ndt = { ...this.addeditdata };
      let tblinks = [];
      for (const i of ndt.__tbtotals?.items || []) {
        tblinks.push(i._id);
      }
      ndt.__tblinks = tblinks;
      delete ndt._id;
      this.axios.post(url, { data: ndt }).then(dt => {
        if (dt.data.status === "success") {
          if (type === "add") this.$store.commit("sbSuccess", "Tasklist item added");
          else this.$store.commit("sbSuccess", "Tasklist item updated");
          this.addeditdialog = false;
          delete ndt.__tblinks;
          this.viewtaskdialog = false;
          // this.checklist[this.editkey] = {...this.addeditdata};
          // this.calculateDetails();
          // this.checklist.push({});
          // this.checklist.pop();
          this.refreshData();
        } else {
          if (dt.data.data.length > 0) {
            if (dt.data.data[0].index0) this.addeditdataerror = dt.data.data[0].index0;
          } else throw new Error(dt.data.message);
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.addeditloading = false;
      })
    },
    downloadPPT() {
      if (!this.alltaskassigned) this.$store.commit("sbError", "Assign all tasks before generating the PPT");
      else if (this.alltaskopen) this.$store.commit("sbError", "No task closed to generate the PPT");
      else this.$nova.downloadFile('', {}, '/v2/financialclose/analysis/downloadppt/' + this.metadata._id);
    },
    workpaperTemplate() {
      this.$nova.downloadFile('', {}, '/v2/financialclose/analysis/gettasklisttemplate');
    },
    bulkActionUserdilog(type, isLocation) {
      this.isLocation = isLocation || false
      this.selectaction = 'associate';
      this.bulkActionUser(type);
    },
    actionselection(select, type) {
      if (select === 'remove') {
        this.bulkactionuserselected = true;
        this.bulkActionUser(false);
      } else {
        this.bulkActionUser(type);
      }
    },
    bulkActionUser(type) {
      let selecteditems = this.multiselectdata;
      let existingusers = new Set();
      let associatedusers = [...(this.metadata.managerlist || []), ...(this.metadata.userlist || [])];
      let possibleids = [];
      if (type) {
        this.bulkactionuserselected = false;
        if (type === 'preparer') this.bulkactionuserusertype = "Preparer";
        else if (type === 'approver') this.bulkactionuserusertype = "Approver";
      }
      for (const i of this.checklist) {
        if (selecteditems.indexOf(i._id) > -1) {
          if (this.bulkactionuserusertype === 'Preparer') (i.preparer || []).forEach(existingusers.add, existingusers);
          else if (this.bulkactionuserusertype === 'Approver') (i.approver || []).forEach(existingusers.add, existingusers);
        }
      }
      if (!this.bulkactionuserselected) {
        for (const i of (this.formdata.users || [])) {
          if (associatedusers.indexOf(i._id) > -1) this.possibleusers.push(i);
        }
      } else {
        possibleids = Array.from(existingusers);
        // console.log(possibleids);
        this.possibleusers = [];
        for (const i of (this.formdata.users || [])) {
          // console.log(i);
          if (possibleids.indexOf(i._id) > -1) this.possibleusers.push(i);
        }
      }
      this.bulkactionuserdialog = true;
    },
    bulkManageUser(action, usertype, userid, selectitems, changeuserid) {
      let ndt = {};
      this.bulkactionuserloading = true;
      if (usertype === "Location") {
        if (userid === "") {
          this.bulkactionuserloading = false;
          throw new Error("Please select location");
        }
      }
      if (action === 'replace') {
        ndt = { fromuserid: userid, touserid: changeuserid, type: action };
      } else {
        ndt = { userid: userid, usertype: (usertype || "").toLowerCase(), type: action };
      }
      let fdt = { type: "checklist", _id: selectitems };

      console.log(ndt, fdt);
      let successflag = false;
      this.axios.post("/v2/financialclose/analysis/bulkmanageuser/" + this.metadata._id, { data: [ndt], filter: fdt })
        .then(dt => {
          if (dt.data.status === "success") {
            this.bulkactionuserdialog = false;
            let successMessage = "";
            if (action === "associate") successMessage = "User Added";
            else if (action === "remove") successMessage = "User Removed";
            else if (action === "move") successMessage = "Change to " + usertype;
            else if (action === "replace") successMessage = "User Replaced";
            this.$store.commit("sbSuccess", successMessage);
            this.changetouser = "";
            successflag = true;
          } else {
            throw new Error(dt.data.message || "Error changing status");
          }
        })
        .catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
        })
        .finally(() => {
          this.bulkactionuserloading = false;
          if (successflag) this.refreshData();
        });
    },
    deleteChecklistItem() {
      this.deleteloading = true;
      let url = "/v2/financialclose/analysis/deletechecklist/" + this.id + '/c/' + this.deleteitem._id;
      this.axios.post(url, {}).then(dt => {
        if (dt.data.status === "success") {
          this.$store.commit("sbSuccess", "Tasklist item deleted");
          this.deletedialog = false;
          this.refreshData();
        } else throw Error(dt.data.message || "Unknown error")
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.deleteloading = false;
        this.viewtaskdialog = false;
      })
    },
    linkBot() {
      this.linkbotloading = true;
      let url = "/v2/financialclose/analysis/linkbot/" + this.id;
      this.axios.post(url, { data: [{ botid: this.selectedbot, task_id: this.addeditdata._id, bot_result_tags: this.leaseScheduleValue }] }).then(dt => {
        if (dt.data.status === "success") {
          this.$store.commit("success", "Bot Linked");
          this.linkbotdialog = false;
          this.checklist[this.addeditdata.__key].botid = this.selectedbot;
          this.selectedbot = "";
          this.calculateDetails();
        } else throw Error(dt.data.message || "Unknown error")
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.linkbotloading = false;
      })
    },
    async bulklinkBot() {
      try {
        this.bulklinkbotloading = true;
        let successMessageSent = false;
        const url = "/v2/financialclose/analysis/linkbot/" + this.id;
        const requests = Object.keys(this.addeditdata).map(async (key) => {
          const value = this.addeditdata[key];
          const response = await this.axios.post(url, {
            data: [{ botid: this.selectedbot, task_id: value, bot_result_tags: this.leaseSchedulebulkValue[value] }],
          });
          if (response.data.status === "success" && !successMessageSent) {
            this.$store.commit("sbSuccess", "Bulk Bot Linked");
            successMessageSent = true;
            this.bulklinkbotdialog = false;
            this.leaseSchedulebulkValue = {};
            // this.checklist[this.addeditdata.__key].botid = this.selectedbot;
            this.selectedbot = "";
            this.calculateDetails();
            this.getData();
            this.bulklinkbotloading = false;
          } else {
            throw Error(response.data.message || "Unknown error");
          }
        });
        await Promise.all(requests);
        console.log("All requests resolved successfully");
      } catch (err) {
        //this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.error(err);
      } finally {
        this.bulklinkbotloading = false;
      }
    },
    unlinkBot(v) {
      this.loading = true;
      let url = "/v2/financialclose/analysis/unlinkbot/" + this.id;
      this.axios.post(url, { data: [v._id] }).then(dt => {
        if (dt.data.status === "success") {
          this.$store.commit("sbSuccess", "Bot Unlinked");
          this.checklist[v.__key].botid = "";
          this.selectedbot = "";
          this.calculateDetails();
          this.getData();
          this.loading = false;
        } else throw Error(dt.data.message || "Unknown error")
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    bulkunlinkBot(v) {
      this.loading = true;
      let url = "/v2/financialclose/analysis/unlinkbot/" + this.id;
      this.axios.post(url, { data: [v] }).then(dt => {
        if (dt.data.status === "success") {
          this.$store.commit("sbSuccess", "Bot Unlinked");
          //  this.checklist[v.__key].botid  = "";
          this.selectedbot = "";
          this.calculateDetails();
          this.getData();
          this.loading = false;
        } else throw Error(dt.data.message || "Unknown error")
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },


    sendForReview() {
      this.sendforreviewloading = true;
      let url = "/v2/financialclose/analysis/sendforreview/" + this.id + "/c/" + this.sendforreviewdata._id;
      this.axios.post(url, { data: { remark: this.sendforreviewremark } }).then(dt => {
        if (dt.data.status === "success") {
          this.$store.commit("sbSuccess", "Tasklist sent for review");
          this.sendforreviewdialog = false;
          this.files=[];
          this.filesbulk=[];
          this.sendforreviewdata.stage = 2;
          this.checklist[this.sendforreviewdata.__key] = { ...this.sendforreviewdata };
          this.calculateDetails();
          // this.refreshData();
        } else throw Error(dt.data.message || "Unknown error")
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.sendforreviewloading = false;
      })
    },
    addHelpdeskQuery() {
      this.helpdeskloading = true;
      let ndt = { issue: this.helpdeskitem.description, description: this.helpdeskitem.__ticketdescripton, modulename: this.$route.meta.modulename };
      let url = "/v2/financialclose/analysis/addfunctionalquery/" + this.metadata._id + "/c/" + this.helpdeskitem._id;
      this.axios.post(url, { data: ndt }).then(dt => {
        if (dt.data.status === "success" && dt.data.data.length > 0) {
          this.$store.commit("sbSuccess", "Ticket raised");
          this.helpdeskdialog = false;
          this.$nova.gotoLink({ path: "/financialclose/functionalsupport/" + this.metadata._id + "/support/" + dt.data.data[0]._id });
        } else throw Error(dt.data.message || "Unknown error")
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.helpdeskloading = false;
      })
    },
    openHelpdeskDialog(d) {
      this.helpdeskitem = { ...d };
      this.helpdeskdialog = true;
      this.helpdeskitemnew = false;
      this.getExistingHelpDesklist();
    },
    getExistingHelpDesklist() {
      this.helpdeskloading = true;
      let url = "/v2/financialclose/analysis/getexistingtickets/" + this.metadata._id + "/c/" + this.helpdeskitem._id;
      this.axios.post(url).then(dt => {
        if (dt.data.status === "success") {
          this.helpdeskitem.__existingtickets = dt.data.data || [];
          if ((this.helpdeskitem.__existingtickets || []).length === 0) this.helpdeskitemnew = true;
        } else throw Error(dt.data.message || "Unknown error")
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.helpdeskloading = false;
      })
    },
    getData() {
      this.loading = true;

      return this.axios.post("/v2/financialclose/analysis/getdetails/" + this.id).then(dt => {
        if (dt.data.status === "success" && dt.data.data.length > 0) {
          let data = dt.data.data[0] || {}
          this.metadata = data.metadata || {};
          this.$store.commit("setProjectName", data.metadata);
          if (this.metadata.stage === 3) this.stagefilter = [0, 1, 2, 3];
          this.stage = this.metadata.stage;
          this.tb = data.tb || [];
          this.pbc = data.pbcitems || [];
          this.wholeChecklist = data.checklist || []
          this.checklist = data.checklist || [];
          if (this.metadata.managerlist.indexOf(this.userid) === -1) {
            this.filterByPreparer()
          }
          this.summary = data.summary || {};
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).then(() => {
        return this.axios.post("/v2/financialclose/analysis/getformdata");
      }).then(dt => {
        if (dt.data.status === "success") {
          this.formdata = dt.data.data[0] || {};
          this.formdata.users.push({ "name": this.$store.state.user.name, "_id": this.$store.state.user.id, "displayname": this.$store.state.user.name + "(" + this.$store.state.user.email + ")", "email": this.$store.state.user.email });
          this.formdata.locations = this.formdata.locations || []
          this.userlist = {};
        } else throw new Error(dt.data.message);
      }).then(() => {
        return this.axios.post("/v2/financialclose/location/get").then((dt) => {
          if (dt.data.status === "success") {
            this.formdata.locations = dt.data.data || [];
            console.log(this.formdata.locations)
            this.location = {};
            this.loading = false;
          } else throw new Error(dt.data.message);
        });
      }).then(() => {
        this.axios.post("/v2/financialclose/project/threshold/get/" + this.id).then((dt) => {
          if (dt.data.status === "success") {
            this.locationListdata = dt.data.data;
            let checklistMapping = this.checklist.filter(obj1 => {
              obj1.threshold = 'NA'
              return this.locationListdata.filter(obj2 => {
                if (obj2.location === obj1.location) {
                  obj1.threshold = obj2.threshold
                }
                return obj1;
              });
            })
            this.checklist = checklistMapping;
          }
        }).then(() => {
          this.axios.post("/v2/financialclose/masters/threshold/get").then((dt) => {
            if (dt.data.status === "success") {
              this.locationListdata = dt.data.data;
              let checklistMapping = this.checklist.filter(obj1 => {
                if (obj1.threshold === 'NA') {
                  obj1.threshold = 0;
                  return this.locationListdata.filter(obj2 => {
                    if (obj2.location === obj1.location) {
                      obj1.threshold = obj2.threshold
                    }
                    return obj1;
                  });
                } else {
                  return obj1;
                }
              })
              this.checklist = checklistMapping;
            }
          });
        }). then(()=>{
            this.axios.post("/v2/financialclose/pbc/project/getrequests/"+this.metadata.pbcproject).then((dt) => {
              if (dt.data.status === "success") {
                this.infomangerrequests = dt.data.data.map(({ _id, category, description, stage, selectlist, reference }) => ({
                  _id,
                  category,
                  description,
                  stage,
                  selectlist,
                  reference
                }));
            }
          });
        })
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
      }).finally(() => {
        this.calculateDetails();
        this.loading = false;
      })
    },
    calculateDetails() {
      let tbtotals = {};
      let closing_amount_to_subtract = []
      let tb_cat_subcat_sum = {};
      for (const i of this.tb) {
        this.tbjson[i._id] = i;
        let closing_info = { category: i.category, subcategory: i.subcategory, closing_amount: 0 }
        if (i.reference_detail.length > 0) {
          // console.log(JSON.stringify(i))
          for (const j of (i.reference_detail || [])) {
            tbtotals[j._id] = tbtotals[j._id] || { total: 0, items: [] };
            tbtotals[j._id].items.push(i);
            tbtotals[j._id].total = tbtotals[j._id].total + i.closing_amount || 0;
            closing_info.closing_amount = tbtotals[j._id].total
          }
          closing_amount_to_subtract.push(closing_info)
        }
        const category = i.category;
        const subcategory = i.subcategory;
        const location = 'location' in i ? i.location : '';
        const key = `${category}-${subcategory}`;
        tb_cat_subcat_sum[key] = tb_cat_subcat_sum[key] || {
          sum: 0,
          category: category,
          subcategory: subcategory,
          locations: {}
        };
        tb_cat_subcat_sum[key].sum += i.closing_amount || 0;
        if (location) {
          tb_cat_subcat_sum[key].locations[location] = (tb_cat_subcat_sum[key].locations[location] || 0) + (i.closing_amount || 0);
        }
        // console.log("This is tb_cat_subcat: ",JSON.stringify(tb_cat_subcat_sum))
      }
      for (const ele of closing_amount_to_subtract) {
        if (tb_cat_subcat_sum[`${ele.category}-${ele.subcategory}`]) {
          tb_cat_subcat_sum[`${ele.category}-${ele.subcategory}`].sum -= ele.closing_amount;
        }
      }
      this.pbcdata = {};
      let pbccount = {};
      for (const i of (this.pbc || [])) {
        this.pbcdata[i._id] = i;
        for (const j of (i.reference || [])) {
          pbccount[j] = pbccount[j] || { total: 0, completed: 0 };
          pbccount[j].total++;
          if (i.stage === 2) pbccount[j].completed++;
        }
      }
      for (const x of this.checklist) {
        if ((x.preparer || []).length === 0 || (x.approver || []).length === 0) this.alltaskassigned = false;
        if (x.stage === 3) this.alltaskopen = false;
        this.reflist.push(x.ref);
        x.__pbccount = pbccount[x._id] || {};
        if (pbccount[x._id]) console.log(pbccount[x._id]);
        if (!Object.keys(tbtotals[x._id] || {}).includes("total")) {
          tbtotals[x._id] = { total: 0, items: [] };
          let tbitem = this.tb.filter(item => {
            if (Object.keys(item).includes("location")) {
              if (item.category === x.category && item.subcategory === x.subcategory && item.location[0] === x.location) {
                return item
              }
            }
          });
          if (Object.keys(tb_cat_subcat_sum).includes(`${x.category}-${x.subcategory}`)) {
            if (x.location in tb_cat_subcat_sum[`${x.category}-${x.subcategory}`].locations) {
              tbtotals[x._id] = { total: 0, items: [] };
              for (const j of tbitem) {
                tbtotals[x._id].items.push(j);
                tbtotals[x._id].total = tbtotals[x._id].total + j.closing_amount || 0;
              }
            }
          }
        }
        x.__tbtotals = tbtotals[x._id] || {};
      }

      let colors = this.$nova.getColors((this.formdata.users || []).length, 0.2);
      // console.log(colors);
      for (let i = 0; i < (this.formdata.users || []).length; i++) {
        const el = (this.formdata.users || [])[i];
        this.userlist[el._id] = el;
        this.userlist[el._id].color = colors[i];
        //const ll= (this.formdata.location)
        //this.locationlist[ll.location] = ll         
      }
      this.formatChecklistData();
    },
    changeChecklistStatus(analysis, type) {
      this.loading = true;
      let idtxt = analysis;
      let data = { type: type };
      if (analysis instanceof Array) {
        idtxt = "bulk";
        data.checklist = analysis;
      }
      this.axios.post("/v2/financialclose/analysis/changecheckliststage/" + this.metadata._id + "/a/" + idtxt, { data: [data] }).then(dt => {
        if (dt.data.status === "success") {
          this.$store.commit("sbSuccess", "Status Updated");
        } else throw dt.data.message || "Error changing status";
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
        this.refreshData();
      });
    },
    populatepbclinkdialogitems(data = {}, opendialog = true) {
      let items = []
      for (const k in this.pbcdata) {
        if (Object.hasOwnProperty.call(this.pbcdata, k)) {
          const el = this.pbcdata[k];
          if ((el.reference || []).indexOf(data._id) > -1) items.push(el);
        }
      }
      this.pbclinkdialogitems = items;
      this.pbclinkdialogchecklistdata = data;
      if (opendialog) this.pbclinkdialog = true;
    },

    populatetblinkdialogitems(data = {}, opendialog = true) {
      console.log(data, "data");
      // this.filterloaction(data.location,((data.__tbtotals || {}).items || []));
      // console.log(this.filterloaction(data.location,((data.__tbtotals || {}).items || [])),"GGGGGGGGG");
      this.tblinkdialogitems = [];
      this.tblinkdialogitems = (data.__tbtotals || {}).items || [];
      this.tblinkdialogchecklistdata = data;
      if (opendialog) this.tblinkdialog = true;
    },
    populateschedulelinkdialogitems(data = {}, opendialog = true) {
      this.schedulelinkdialogitems = [];
      this.schedulelinkdialogitems = data.result?.value?.fc_table || [];
      this.schedulelinkdialogchecklistdata = data;
      if (opendialog) this.schedulelinkdialog = true;
    },


    async uploadFiles(id) {
      let totalSize = 0;
      let arrayPush = [];
      let files = this.files;
      this.sendforreviewloading = true
      // Check if files is an array and has elements
      if (Array.isArray(files) && files.length > 0) {
        files.forEach((file) => {
          if (file.name && (this.fileprocessing[id] || 0) === 0) {
            totalSize += file.size;
          }
        });

        // Validate total file size (50 MB limit)
        if (totalSize > 50 * 1024 * 1024) {
          this.$store.commit("sbError", "Total file size exceeds the limit (50 MB)");
          this.files[id] = null;
          return;
        }

        // Process each file in the array
        const uploadPromises = files.map(async (file) => {
          if (file.name && (this.fileprocessing[id] || 0) === 0) {
            try {
              // Upload file using Nova (assuming it returns a promise)
              let dt = await this.$nova.uploadFile(this.axios, file, {
                progressfunction: (e) => {
                  let p = (e.loaded * 100) / e.total;
                  this.fileprocessingpercent[id] = p.toFixed(0);
                  this.uploadcounter = this.uploadcounter + 0.01;
                },
              });

              // Store the entire response object
              arrayPush.push(dt);
            } catch (err) {
              // Handle errors during file upload
              this.fileprocessing[id] = 0;
              this.$store.commit("sbError", err.message || err || "Unknown error!");
              console.error(err);
            }
          }
        });

        // Wait for all upload promises to resolve
        await Promise.all(uploadPromises);

        // Check if any response has a status of "success"
        if (arrayPush.some((response) => response.data.status === "success")) {
          this.dataArray = arrayPush.flatMap((response) => response.data.data.map((item) => {
            return { "filename": item.uploadname, "file_id": item._id, "file_path":item.path }
          }));
          this.fileprocessing[id] = 2;

          // Rest of your code...
          let data = {
            upload: this.dataArray,
            project: this.id,
            checklist: id
          };

          // Continue with the post request
          try {
            let response = await this.axios.post("/v2/financialclose/analysis/storeworkpaper", {
              data: [data]
            });

            if (response.data.status === "success" && response.data.data.length > 0) {
              this.$store.commit("sbSuccess", "File uploaded");
              this.fileprocessing[id] = 0;
              this.sendforreviewdata.stage = 1;
              this.checklist[this.sendforreviewdata.__key] = {
                ...this.sendforreviewdata
              };
              this.sendforreviewdialog = false;
            } else {
              throw new Error("Error uploading file");
            }
          } catch (axiosError) {
            this.fileprocessing[id] = 0;
            this.$store.commit("sbError", axiosError.message || axiosError || "Unknown error!");
            console.error(axiosError);
          } finally {
            // Reset file and loading states
            this.files=[];
            this.filesbulk=[];
            this.refreshData();
            this.sendforreviewloading = false;
          }
        } else {
          this.fileprocessing[id] = 0;
          this.$store.commit("sbError", "Unable to upload file");
          this.sendforreviewloading = false
        }
      } else {
        this.sendforreviewloading = false
        this.$store.commit("sbError", "No files to upload");
        this.sendforreviewloading = false
      }
      this.files=[];
      this.filesbulk=[];
      const fileInput = document.getElementById('fileInput');
      if (fileInput) {
        fileInput.value = ''; 
      }
      this.sendforreviewdialog=false
    },

    async uploadFilesBulk( taskIds) {
      this.log = 0;
      let files= this.files;
      try {
        let totalSize = 0;
        let dataArrayList = [];
        if (Array.isArray(files) && files.length > 0) {
          files.forEach((file) => {
            if (file.name) {
              totalSize += file.size;
            }
          });
          if (totalSize > 50 * 1024 * 1024) {
            this.$store.commit("sbError", "Total file size exceeds the limit (50 MB)");
            return;
          }
          this.sendforreviewloadingbulk = true;
          const uploadPromises = files.map(async (file) => {
            if (file.name) {
              try {
                let response = await this.$nova.uploadFile(this.axios, file, {});
                dataArrayList.push(response);
              } catch (uploadError) {
                this.$store.commit("sbError", uploadError.message || "Error uploading file");
                console.error(uploadError);
              }
            }
          });
          this.bulkfilesloading = 0;
          await Promise.all(uploadPromises);
          if (dataArrayList.some((response) => response.data.status === "success")) {
            this.dataArray = dataArrayList.flatMap((item) => item.data.data.flatMap((item) => {
              return { "filename": item.uploadname, "file_id": item._id , "file_path":item.path }
            }));
            for (let index = 0; index < taskIds.length; index++) {
              let data = {
                upload: this.dataArray,
                project: this.id,
                checklist: taskIds[index],
                removelocalfile: taskIds.length - index === 1? true: false,
              };
              try {
                this.bulkfilesloading = 0;
                let response = await this.axios.post("/v2/financialclose/analysis/storeworkpaper", { data: [data] });
                if (response.data.status === "success" && response.data.data.length > 0) {
                  this.$store.commit("sbSuccess", "File uploaded");
                  this.bulkfilesloading = 1;
                  if (index === (taskIds.length - 1)) {
                    this.sendForReviewDialogbulk = false;
                    this.files=[];
                    this.filesbulk=[];
                    this.refreshData();
                  }
                } else {
                  throw new Error("Error Uploading File")
                }
              } catch (axiosError) {
                this.files=[];
                this.filesbulk=[];
                this.$store.commit("sbError", axiosError.message || "Unknown error!");
              }
            }
            this.sendforreviewloadingbulk = false;
          } else {
            this.$store.commit("sbError", "Unable to upload file ");
            this.sendforreviewloadingbulk = false;
          }
        } else {
          this.$store.commit("sbError", "No files to upload");
          this.sendforreviewloadingbulk = false;
        }
      } catch (error) {
        console.error(error);
        this.sendForReviewDialogbulk = false;
      } finally {
        this.filesbulk = {};
        this.bulkfilesloading = 1;
      }
      this.files=[];
      this.filesbulk=[];
      const fileInput = document.getElementById('fileInput');
      if (fileInput) {
        fileInput.value = ''; 
      }
      this.sendforreviewdialogbulk=false
    },


    opensendForReviewDialogbulk(taskids) {
      this.filesbulk = [];
      this.files=[];
      const fileInput = document.getElementById('fileInput');
      if (fileInput) {
        fileInput.value = ''; 
      }
      this.sendforreviewdialogbulk = true;
      console.log(taskids);
    },


    downloadExcel() {
      let dt = [
        [undefined],
        [
          undefined,
          { v: this.$store.state.clientlist[0].name || "", s: { font: { bold: true, sz: 20 } } },
        ],
        [
          undefined,
          { v: this.metadata.description, s: { font: { bold: true, sz: 14 } } },
        ],
        [
          undefined,
          { v: this.$nova.formatDate(this.metadata.from) + " - " + this.$nova.formatDate(this.metadata.from), s: { font: { bold: true, sz: 14 } } },
        ],
        [
          undefined
        ],
        [
          undefined,
          { v: "Reference", s: { font: { bold: true } } },
          { v: "Task Description", s: { font: { bold: true } } },
          { v: "Category", s: { font: { bold: true } } },
          { v: "Sub Category", s: { font: { bold: true } } },
          { v: "Location", s: { font: { bold: true } } },
          { v: "Assertions", s: { font: { bold: true } } },
          { v: "Due Date", s: { font: { bold: true } } },
          { v: "Amount as per Trial Balance", s: { font: { bold: true } } },
          { v: "Amount as per Schedule", s: { font: { bold: true } } },
          { v: "Difference", s: { font: { bold: true } } },
          { v: "Purpose", s: { font: { bold: true } } },
          { v: "Instruction", s: { font: { bold: true } } },
          { v: "Preparer", s: { font: { bold: true } } },
          { v: "Approver", s: { font: { bold: true } } },
          { v: "Status", s: { font: { bold: true } } },
        ]];
      for (const v of this.checklist) {
        dt.push([
          undefined,
          v.ref,
          v.description,
          v.category,
          v.subcategory,
          v.location,
          (v.assertions || []).join(", "),
          { v: (v.subcategory) ? this.$nova.formatDate(v.duedate) : '', z: 'dd-mmm-yyyy', t: 'd' },
          { v: ((v.__tbtotals || {}).total || 0).toFixed(0), z: '#,##', t: 'n' },
          { v: (Number((v.result || {}).value?.total_value) || 0).toFixed(0), z: '#,##', t: 'n' },
          { v: (((v.__tbtotals || {}).total || 0) - (Number((v.result || {}).value?.total_value) || 0)).toFixed(0), z: '#,##', t: 'n' },
          v.purpose,
          v.instruction,
          this.getUserList(v.preparer),
          this.getUserList(v.approver),
          this.getStageText(v.stage)
        ])
      }
      // console.log(dt);

      // Extract Data (create a workbook object from the table)
      let workbook = styledxlsx.utils.book_new();
      let worksheet = styledxlsx.utils.aoa_to_sheet(dt);
      styledxlsx.utils.book_append_sheet(workbook, worksheet, "Tasklist");
      styledxlsx.writeFile(workbook, this.metadata.description + " - Tasklist.xlsx");

      // console.log(xlsx);
    },
    getUserList(x) {
      let rdt = [];
      for (const i of x) {
        rdt.push(this.userlist[i]?.displayname || "")
      }
      return rdt.join(", ")
    },
    getStageText(x) {
      if (x === 0) return "Pending Submission";
      else if (x === 1) return "Processing";
      else if (x === 2) return "Pending Approval";
      else if (x === 3) return "Closed Task";
      else return "Unknown";
    },
    sortMainObject(main) {
      const sortObjectsByStage = (objects) => {
        if (this.selectedusertype === 2) {

          return objects.sort((a, b) => {
            if (a.stage === 0) return -1;
            if (b.stage === 0) return 1;
            if (a.stage === 2) return -1;
            if (b.stage === 2) return 1;
            return 0;
          });
        }
        else if (this.selectedusertype === 1) {
          return objects.sort((a, b) => {
            if (a.stage === 2) return -1;
            if (b.stage === 2) return 1;
            if (a.stage === 0) return -1;
            if (b.stage === 0) return 1;
            return 0;
          });
        }
      };

      let sortedMain = {};
      Object.keys(main).forEach((key) => {
        sortedMain[key] = sortObjectsByStage(main[key]);
      });

      // let sortedKeys = Object.keys(sortedMain).sort((a, b) => {
      //   let hasStage0A = sortedMain[a].some((obj) => obj.stage === 0);
      //   let hasStage0B = sortedMain[b].some((obj) => obj.stage === 0);
      //   if (hasStage0A && !hasStage0B) return -1;
      //   if (!hasStage0A && hasStage0B) return 1;
      //   return 0;
      // });

      // sortedMain = {};
      // sortedKeys.forEach((key) => {
      //   sortedMain[key] = sortedMain[key];
      // });
    },
    formatChecklistData: function () {
      let dta = [];
      let catdata = {};
      for (let i = 0; i < (this.checklist || []).length; i++) {
        const el = this.checklist[i];
        el.__key = i;
        let cat = el.category;
        el.location = el.location || [];
        if ((el.location).length > 0) {
          el.locationItem = (this.formdata.locations.filter(obj => obj["_id"] === el.location)) || [{ "name": "" }];
          if (el.locationItem.length > 0) {
            el.locationName = el.locationItem[0].name || "";
          }
          else el.locationName = "";

        }

        if (this.stagefilter.indexOf(el.stage) > -1) {
          catdata[cat] = catdata[cat] || [];
          catdata[cat].push(el);
        }
      }
      this.sortMainObject(catdata)
      for (const k in catdata) {
        if (Object.hasOwnProperty.call(catdata, k)) {
          const el = catdata[k];
          dta.push({ type: 'category', name: k });
          dta = [...dta, ...el];
        }
      }
      this.formattedchecklistdata = dta;
      this.filterformated()
    },
    nameReturn(ids) {
      let arrayTem = [];
      for (const id of ids) {
        arrayTem.push(this.userlist[id])
      }
      return arrayTem;
    },
    filterloaction(id, item) {
      return item.filter(obj => obj.location && obj.location.includes(id));
    },
    getTaskDeatils(id) {
      const foundItem = this.formattedchecklistdata.find(item => item._id === id);
      return foundItem ? foundItem : "ID not found";
    },
    updateFiles(newFiles) {
      this.files = newFiles;
      console.log("esxdrctfgsdf", newFiles);
    },
    updateFilesBulk(newFilesBulk) {
      console.log("esxdrctfg", newFilesBulk);
      this.filesbulk = newFilesBulk;
    },
    // dragdrop file

    dragover(event) {
      event.preventDefault();
      this.isDragging = true;
    },
    dragleave(event) {
      event.preventDefault();
      this.isDragging = false;
    },
    drop(event) {
      event.preventDefault();
      this.isDragging = false;
      const files = event.dataTransfer.files;
      this.files = Array.from(files);
    },
    onChange(event) {
      console.log("event",event.target, event.target.files);
      const files = event.target.files;
      this.files = Array.from(files);
    },
    handleCheckboxChange(item) {
      const index = this.checkedTaskdetails.findIndex(task => task._id === item._id);
      if (index !== -1) {
        this.checkedTaskdetails.splice(index, 1);
      } else {
        this.checkedTaskdetails.push(item);
      }
    }

  },
  computed: {
    filteredItems() {
      if (this.searchcustomfilter === "" || this.searchcustomfilter === undefined) {
        return this.tb.filter(item => {
          if (Object.keys(item.location_detail[0] || {}).includes('_id')) {
            if (item.location_detail[0]._id === this.addeditdata.location) {
              return item;
            }
          }
        }
        );
      } else {
        return this.tb.filter(item => {
          if (Object.keys(item.location_detail[0] || {}).includes('_id')) {
            if (item.location_detail[0]._id === this.addeditdata.location) {
              return Object.values(item).some(val =>
                val !== null && val !== undefined &&
                val.toString().toLowerCase().includes(this.searchcustomfilter.toLowerCase())
              )
            }
          }
        }
        );
      }
    },
    totalPages() {
      return Math.ceil(this.filterList.length / this.itemsPerPage);
    },
    finaluserlist() {
      let nl = [];
      for (const i of this.metadata.managerlist) {
        let ud = { ...this.userlist[i] };
        ud.type = "manager";
        ud._id = this.userlist[i]._id;
        ud.name = this.userlist[i].name;
        nl.push(ud);
      }
      for (const i of this.metadata.userlist) {
        let ud = { ...this.userlist[i] };
        ud.type = "general";
        ud._id = this.userlist[i]._id;
        ud.name = this.userlist[i].name;
        nl.push(ud);
      }
      return nl;
    },
    filteredUserList() {
      return this.filterListitems.userlists.filter(user => {
        return this.userSearch === '' || (this.userlist[user] && this.userlist[user].name.toLowerCase().includes(this.userSearch.toLowerCase()));
      });
    },
    filteredLocationList() {
      return this.filterListitems.locations?.filter(location => {
        return this.locationSearch === '' || (location.toLowerCase().includes(this.locationSearch.toLowerCase()));
      });
    },

  },

  watch: {
    "addeditproject.from": function () {
      this.generateDescription();
    },
    "addeditproject.to": function () {
      this.generateDescription();
    },
    "stagefilter": function () {
      this.formatChecklistData();
    },
    "selectedusertype": function () {
      this.formatChecklistData();
    },
    "addeditdata.location": function () {
      this.addeditdata.location = this.addeditdata.location || [];
      this.eligibleusers.preparer = [];
      this.eligibleusers.approver = [];
      if (this.addeditdata.location.length > 0) {
        let locationObject = this.formdata.locations.filter(obj => obj['_id'] === this.addeditdata.location)
        let filter;
        for (const approver in locationObject[0].approver) {
          filter = this.formdata.users.filter(obj => obj['_id'] === locationObject[0].approver[approver]);
          this.eligibleusers.approver.push(filter[0])
        }
        for (const preparer in locationObject[0].preparer) {
          filter = this.formdata.users.filter(obj => obj['_id'] === locationObject[0].preparer[preparer]);
          this.eligibleusers.preparer.push(filter[0])
        }
        // this.eligibleusers.preparer = this.formdata.users;
        // this.eligibleusers.approver = this.formdata.users;
        this.isLocationSelected = true;
      }
      else {
        this.isLocationSelected = false;
      }
    },
    'filterSelectedData.selectedTaskType': function () {
      // this.formatChecklistData();
      if (this.selectedusertype === 1 && this.metadata.managerlist.indexOf(this.userid) === -1) {
        this.filterByApprover()
      }
      else if (this.selectedusertype === 2 && this.metadata.managerlist.indexOf(this.userid) === -1) {
        this.filterByPreparer()
      }
    },
    'filterSelectedData.selectedlocation': function () {
      this.formatChecklistData()
    },
    'checklist': function () {
      this.formatChecklistData();
    },
    selectedFilters(newFilters) {
      if(newFilters.length>0){
        this.TaskStage = this.getTaskStage(newFilters);
        this.filterSelectedData.selectedTaskType = [...newFilters, this.selectedFilter];
        if(this.metadata.managerlist.indexOf(this.userid) !== -1)
        {
          this.filters()
        }
      }
    },
    selectedFilter(newFilter) {
        this.TaskStage = this.getTaskStage([newFilter]);
        this.filterSelectedData.selectedTaskType = newFilter;
        this.selectedFilters=[newFilter]
      
    },
    currentPage: 'updatePaginatedList',
    filterList: 'updatePaginatedList',
  }

}
</script>

<style scoped>
.custom-font {
  font-family: sans-serif;
  letter-spacing: 3px;

}

.grey-background {
  background-color: #667085 !important;
  color: white !important;
  border: 1px solid #667085;
}

.filterlists {
  margin-left: 280px !important;
}

.max-width-div {
  max-width: 600px;
  /* Adjust the value as needed */
}

.gap-tab {
  gap: 15px;
}

.padding-tab {
  padding: 16px;
  border-radius: 12px;
}

.width-tab {
  width: 25%;
  align-items: center;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06);
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
}

.font-size-font {
  font-size: 26px !important;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.02em;
}

.v-data-table th {
  background-color: #667085 !important;
  color: white !important;
}

.v-tab {
  height: 45px !important;
}

.v-slide-group__content,
.v-tabs-bar__content {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.v-dialog,
.v-dialog--active,
.v-dialog--scrollable,
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 65% !important;
}

.v-btn--icon.v-size--default {
  height: 36px !important;
  width: 30px !important;
}

.selected-tab {
  background-color: #667085 !important;
  color: white !important;
}

.radius-small {
  border-radius: 8px !important;
}


.dropdown {
  display: none;
}

.dropdown ul {
  list-style-type: none;
  padding: 0;
}

.dropdown ul li {
  cursor: pointer;
  padding: 10px;
}

.dialog-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 20px;
}

.gap-4 {
  gap: 4px;
}

.v-list {
  width: 200px;
  margin-right: 10px;
}

.tab-shadow {
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06);
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
}

.v-list-item {
  margin-bottom: 8px;
}

.v-list-item-title {
  font-weight: bold;
}

.v-radio {
  margin-right: 16px;
}

.clip-text {
  text-wrap: wrap;
}

.selected-item {
  border: 1px solid #7F56D9;
  background-color: #F9F5FF;
}

.v-checkbox {
  border-color: #D0D5DD;
  border-width: 1px;
}

.text-field {
  border: 1px solid #ccc;
  height: 100px;
  overflow: hidden;
  border-radius: 8px;
}

.input {
  width: 100%;
  height: 100%;
  border: none;
  padding: 5px;
  box-sizing: border-box;
  outline: none;
}

.line-clamp {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.hover-tabs:hover {
  border: 1px solid #7F56D9;
  background-color: #F9F5FF;

}

.v-menu__content {
  margin-top: 10px !important;
}

.v-menu__content .v-list {
  overflow-y: auto;
}

.custom-list-item {
  padding: 5px !important;
  background-color: white !important;
}

.v-select__selections {
  padding: 0px !important;
}

.font-20 {
  font-size: 18px !important;
}

.bold-font {
  font-size: 18px !important;
  font-weight: 550;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.border-highlight {
  border-color: #6A67CE;
  box-shadow: 0 0 0 1px #6A67CE;
}

.v-checkbox .v-input--selection-controls__ripple {
  border-color: #6A67CE !important;
}

.v-input--checkbox::v-deep {
  .v-label {
    color: #6A67CE;
  }
}

.custom-list-item{
  border:1px solid lightgray !important;
}

.customDropdown .v-input__control .button{
  padding:10px !important    

}


.dragdropmain {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.dropzone-container {
  padding: 4rem;
  background: white;
  border: 2px dashed #98A2B3;
  width: 85%;
}

.hidden-input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}

.file-label {
  font-size: 15px !important;
  display: block;
  cursor: pointer;
}

</style>
